import PropTypes from 'prop-types'
import React, { useEffect, useState } from "react"
import Select from "react-select"

import { Row, Col, FormGroup } from "reactstrap"

//Import mini card widgets
import RevenueChart from "./revenue-chart"
import CampaignsChart from "./campaigns-chart"
import AverageCards from "./average-cards"

let options_date = [
    {label: "Last Month", value: "last_month"},
    {label: "Last 3 months", value: "last_3_months"},
    {label: "Last 6 months", value: "last_6_months"},
    {label: "Current Year", value: "current_year"},
    {label: "Last Year", value: "last_year"},
    {label: "Last 3 Year", value: "last_3_years"},
]

var pieData = {
    series: [1, 2, 3],
    options: {
      labels: ["Series A", "Series B", "Gazetta"],
      colors: ["#556ee6", "#34c38f", "#f46a6a"],
      legend: { show: !1 },
      dataLabels: {enabled: false},
      tooltip: {
        style: {
          fontSize: '11px',
          fontWeight: '400',
          fontFamily: undefined 
        },
      },
      plotOptions: {
        pie: {
          donut: {
            size: "70%",
          },
        },
      },
    },
  }

const DataCharts = props => {

    const today = new Date()
    const [selectedWebsites, setSelectedWebsites] = useState([]);
    const [selectedDates, setSelecteDates] = useState({
        from: formatDate(new Date(today.getFullYear(), today.getMonth()-1, 1)), 
        to: formatDate(new Date(today.getFullYear(), today.getMonth(), 0))
    });

    useEffect(() => {
        let all_websites = [];
        props.websites.map((x) => {
            if (x.value)
                all_websites.push({id: x.value, name: x.label})
        })
        setSelectedWebsites(all_websites)
    }, [props.websites])

    function handleSelectedOptionWebsite(option) {
        if (option.value) {
            setSelectedWebsites([{id: option.value, name: option.label}])
        }
        else {
            let all_websites = [];
            props.websites.map((x) => {
                if (x.value)
                    all_websites.push({id: x.value, name: x.label})
            })
            setSelectedWebsites(all_websites)
        }
    }

    function handleSelectedOptionDate(option) {
        let from = "", 
            to = ""

        if (option.value === "last_month") {
            from = new Date(today.getFullYear(), today.getMonth()-1, 1);
            to = new Date(today.getFullYear(), today.getMonth(), 0);
        }
        else if (option.value === "last_3_months") {
            from = new Date(today.getFullYear(), today.getMonth()-3, 1);
            to = new Date(today.getFullYear(), today.getMonth(), 0);
        }
        else if (option.value === "last_6_months") {
            from = new Date(today.getFullYear(), today.getMonth()-6, 1);
            to = new Date(today.getFullYear(), today.getMonth(), 0);
        }
        else if (option.value === "current_year") {
            from = new Date(today.getFullYear(), 0, 1);
            to = today
        }
        else if (option.value === "last_year") {
            from = new Date(today.getFullYear()-1, 0, 1);
            to = new Date(today.getFullYear()-1, 11, 31);
        }
        else if (option.value === "last_3_years") {
            from = new Date(today.getFullYear()-3, 0, 1);
            to = new Date(today.getFullYear()-1, 11, 31);
        }
        if (option.value) {
            from = formatDate(from)
            to = formatDate(to)
        }
        setSelecteDates({from, to})
    }

    function formatDate(date) {
        var year = date.getFullYear()
        var month = date.getMonth() + 1
        var dt = date.getDate()
        if (dt < 10) 
            dt = '0' + dt
        if (month < 10)
            month = '0' + month
        return year + '-' + month + '-' + dt
    }

    return (
        <React.Fragment>
            <Row className="mb-4">
                <Col className="custom_title">
                    <h4>Data</h4>
                    <div>
                        <FormGroup className="select2-container" style={{width: `${7 * props.websites[0].label.length + 40}px`}}>
                            <Select
                            defaultValue={props.websites[0]}
                            onChange={handleSelectedOptionWebsite}
                            options={props.websites}
                            classNamePrefix="select2-selection"
                            />
                        </FormGroup>
                        <FormGroup className="select2-container" style={{width: `${7 * options_date[0].label.length + 40}px`}}>
                            <Select
                            defaultValue={options_date[0]}
                            onChange={handleSelectedOptionDate}
                            options={options_date}
                            classNamePrefix="select2-selection"
                            />
                        </FormGroup>
                    </div>
                </Col>
            </Row>
            <Row>
                <CampaignsChart selectedWebsites={selectedWebsites} selectedDates={selectedDates}></CampaignsChart>
                <RevenueChart selectedWebsites={selectedWebsites} selectedDates={selectedDates}></RevenueChart>
            </Row>
            <Row className="mb-4">
                <AverageCards selectedWebsites={selectedWebsites} selectedDates={selectedDates}></AverageCards>
            </Row>
        </React.Fragment>
    )
}

export default DataCharts