import PropTypes from 'prop-types'
import React from "react"

import { Row, Col, CardBody, Card, Alert, Container, Label } from "reactstrap"

// Redux
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"

// availity-reactstrap-validation
import { AvForm, AvField, AvGroup, AvInput } from "availity-reactstrap-validation"

//Social Media Imports
// import { GoogleLogin } from "react-google-login"
// import TwitterLogin from "react-twitter-auth"
// import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props"

// actions
import { loginUser, apiError, socialLogin } from "store/actions"

// import images
import rounded_lines from "assets/images/madinad/rounded_lines.svg"
import logo from "assets/images/madinad/logo_login.png"

//Import config
// import { facebook, google } from "../../config"

const Login = props => {
  // handleValidSubmit
  const handleValidSubmit = (event, values) => {
    props.loginUser(values, props.history)
  }

  const signIn = (res, type) => {
    const { socialLogin } = props
    if (type === "google" && res) {
      const postData = {
        name: res.profileObj.name,
        email: res.profileObj.email,
        token: res.tokenObj.access_token,
        idToken: res.tokenId,
      }
      socialLogin(postData, props.history, type)
    } else if (type === "facebook" && res) {
      const postData = {
        name: res.name,
        email: res.email,
        token: res.accessToken,
        idToken: res.tokenId,
      }
      socialLogin(postData, props.history, type)
    }
  }

  //handleGoogleLoginResponse
  // const googleResponse = response => {
  //   signIn(response, "google")
  // }

  //handleTwitterLoginResponse
  // const twitterResponse = e => {}

  //handleFacebookLoginResponse
  // const facebookResponse = response => {
  //   signIn(response, "facebook")
  // }

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <div className="account-pages_wallpaper"><img src={rounded_lines}></img></div>
        <div className="account-pages_content">
        <Container className="account_form">
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={12}>
              <Card className="overflow-hidden">
                  <Link to="/" className="logo_circle">
                    <img src={logo} />
                  </Link>
                  <div className="account-pages_header">
                    <h5 className="text-primary">Welcome Back !</h5>
                    <p>Sign in to continue to Madinad.</p>
                  </div>
                <CardBody className="pt-0">
                <AvForm
                  className="form-horizontal"
                  onValidSubmit={(e, v) => {
                    handleValidSubmit(e, v)
                  }}
                >
                  {props.error && typeof props.error === "string" ? (
                    <Alert color="danger">{props.error}</Alert>
                  ) : null}

                  <div className="form-group">
                    <AvField
                      name="email"
                      label="Username*"
                      value=""
                      className="form-control"
                      placeholder="Enter username"
                      type="username"
                      required
                    />
                  </div>

                  <div className="form-group position-relative">
                    <Link to="/forgot-password" className="text-muted position-absolute" style={{right:0, top:0}}>
                      Forgot your password?
                    </Link>
                    <AvField
                      name="password"
                      label="Password*"
                      value=""
                      type="password"
                      required
                      placeholder="Enter Password"
                    />
                  </div>
                  {/* <div className="form-group">
                    <AvGroup className="d-flex align-items-center" check>
                      <AvInput type="checkbox" name="remember" className="mt-0" />
                      <Label check for="remember">Remember me</Label>
                    </AvGroup>
                  </div> */}
                  <div className="mt-3">
                    <button
                      className="btn btn-primary btn-block waves-effect waves-light"
                      type="submit"
                    >
                      Log In
                    </button>
                  </div>
                </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <Container className="mfooter mt-auto">
            <Row>
                <Col md={8} lg={6} xl={12} className="m-auto">
                    <div className="mfooter_contact">
                        <p>Contact info</p>
                        <ul>
                            <li><a href="mailto:info@madinad.com" target="_blank">info@madinad.com</a></li>
                            <li><a href="tel:2103802064" target="_blank">2103802064</a></li>
                        </ul>
                    </div>
                    <small className="d-block text-center">© 2021 Madinad. Crafted with{" "}<i className="mdi mdi-heart text-danger" /> by 9pixels</small>
                </Col>
            </Row>
        </Container>
        </div>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { error } = state.Login
  return { error }
}

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError, socialLogin })(Login)
)

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func
}