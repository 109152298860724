import {
  GET_ADTAGS,
  GET_ADTAGS_SUCCESS,
  GET_ADTAGS_FAIL,
} from "./actionTypes"

const initialState = {
    count: 0,
    next: null,
    previous: null,
    results: [],
    error: "",
}

const adtags = (state = initialState, action) => {
    switch (action.type) {
      case GET_ADTAGS:
        state = { ...state}
        break
      case GET_ADTAGS_SUCCESS:
        state = { ...state, ...action.payload }
        break
      case GET_ADTAGS_FAIL:
        state = { ...state, error: action.payload }
        break
      default:
        state = { ...state }
        break
    }
    return state
  }
  
  export default adtags