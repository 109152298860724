import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"

const SidebarContent = props => {
  useEffect(() => {
    $('#sidebar-menu').find('a').each(function() {
      if (this.pathname === props.location.pathname) {
        $(this).addClass('active')
        return false
      }
    })
  }, [props.location.pathname])

  return (
    <React.Fragment>
      <div id="sidebar-menu" className="madinadSidebar" style={{ maxHeight: "100%" }}>
        <div className="madinadSidebar_content">
          <Link to="/dashboard">
            <div className="icon_dashboard"></div>
            <span>{props.t("Dashboard")}</span>
          </Link>
          <Link to="/campaigns">
            <div className="icon_campaigns"></div>
            <span>{props.t("Campaigns")}</span>
          </Link>
          <Link to="/billing">
            <div className="icon_billing"></div>
            <span>{props.t("Billing")}</span>
          </Link>
        </div>
      </div>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
