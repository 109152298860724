import React, { useEffect, useLayoutEffect, useState, useRef } from "react"
import { withTranslation } from "react-i18next"
import ReactApexChart from "react-apexcharts"

import {
    Col,
    Container,
    Row,
} from "reactstrap"

// put icons in images load them here and them to images
import date_green_icon from "../../../assets/images/madinad/date_green.svg"
import date_red_icon from "../../../assets/images/madinad/date_red.svg"
import ordered_impressions_icon from "../../../assets/images/madinad/ordered_impressions.svg"
import delivery_rate_icon from "../../../assets/images/madinad/delivery_rate.svg"
import delivered_impressions_icon from "../../../assets/images/madinad/delivered_impressions.svg"
import interaction_rate_icon from "../../../assets/images/madinad/interaction_rate.svg"
import ctr_icon from "../../../assets/images/madinad/ctr.svg"
import website_icon from "../../../assets/images/madinad/website_icon.svg"

import {GET_CAMPAIGN_PUBLISHER_ANALYTICS, GET_CAMPAIGN} from "../../../helpers/url_helper"
import {get} from "../../../helpers/api_helper"


function formatDate(date) {
    if (!(date instanceof Date))
        date = new Date(date)
    var year = date.getFullYear()
    var month = date.getMonth() + 1
    var day = date.getDate()
    if (day < 10) 
        day = '0' + day
    if (month < 10)
        month = '0' + month

    if (isNaN(year))
        return ''
    return day + '/' + month + '/' + year
}

const Reports_madinad = props => {

    const selectOptionsRef = useRef()

    // let series = [{
    //     name: "Revenue",
    //     data: [
    //       [1327964400000,37.95],
    //       [1361919600000,39.60],
    //     ]
    // }]

    let options = {
        chart: {
          type: "area",
          toolbar: "false"
        },
        fill: {
          type: 'gradient',
          gradient: {
            type: "vertical",
            shadeIntensity: 0.2,
            opacityFrom: 0.7,
            opacityTo: 0,
            stops: [0, 60]
         }
        },
        dataLabels: {
          enabled: !1,
        },
        yaxis: {
          labels: {show: false}
        },
        xaxis: {
          labels: {show: false}
        },
        scales: {
          yAxes: [{
              gridLines: {
                  display: false,
                  drawBorder: false,
                  tickMarkLength: false,
              }
          }],
          xAxes: [{
            gridLines: {
                display: false,
                drawBorder: false,
                tickMarkLength: false,
            }
        }]
        },
        axisBorder: {show: false},
        colors: ["#1D56CA"],
        stroke: {
          curve: "smooth",
          width: 2,
        },
        tooltip: {
          x: {
            formatter: function(value) {
                return formatDate(new Date(value))
            }
          },
          y: {
            formatter: function(value) {
                return value;
            }
          }
        },
    }
    let options2 = {...options}
    options2.colors = ['#825BA3']
    let options3 = {...options}
    options3.colors = ['#C24F7A']

    const [loading, setLoading] = useState('loading');
    const [loadingWebsite, setLoadingWebsite] = useState('loading');
    const [loadingGraph, setLoadingGraph] = useState('loading');
    const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
    const [dropdownHeight, setDropdownHeight] = useState('')
    const [selectedOption, setSelectedOption] = useState({label: 'All websites', value: ''})
    const [campaignInfo, setCampaignInfo] = useState({name: '', run_from: '', run_to: '', total_ordered_impressions: '', delivery_rate: ''})
    const [campaignInfoWebsite, setCampaignInfoWebsite] = useState({total_ordered_impressions: '', delivery_rate: ''})
    const [campaignAnalytics, setCampaignAnalytics] = useState({})
    const [campaignAnalyticsGraph, setCampaignAnalyticsGraph] = useState({
        clicks: 0, 
        impressions: 0,
        interactions: 0,
        delivered_impressions: [],
        interaction_rate: [],
        ctr: []
    })
    const [campaignWebsites, setCampaignWebsites] = useState([{ label: "All websites", value: "" }])
    // const [graphData, setGraphData] = useState({'delivered_impressions': [], 'interaction_rate': [], 'ctr': []})

    useLayoutEffect(() => {
        var element = selectOptionsRef.current
        // var active_option = $(element).find('.active')
        // console.log('height of element = ', $(element).outerHeight())
        // console.log('height of active option = ', $(active_option).outerHeight())
        // console.log('dropdown height = ', $(element).outerHeight() - $(active_option).outerHeight())
        setDropdownHeight($(selectOptionsRef.current).outerHeight() + 'px')
        window.addEventListener('resize', () => {
            calculateHeight()
            // this.setState({calculating: true}, this.recalculateHeight.bind(this));
        }, true);

        if (campaignWebsites.length > 1 && campaignInfo.adplacements && campaignInfo.adplacements.length > 0) {
            updateWebsites()
        }
    }, [campaignWebsites])

    useEffect(() => {
        if (campaignWebsites.length > 1 && campaignInfo.adplacements && campaignInfo.adplacements.length > 0) {
            updateWebsites()
        }
        setLoading('')
    }, [campaignInfo])

    useEffect(() => {
        setLoadingWebsite('')
    }, [campaignInfoWebsite])
    

    function updateWebsites() {
        let campaignWebsites_copy = campaignWebsites;
        campaignInfo.adplacements.map((x, index) => {
            campaignWebsites_copy[index + 1].delivery_status = x.delivery_status
            if (x.delivery_status === 'overdelivery') {
                campaignWebsites_copy[index + 1].color = '#F1B44D';
            }
            else if (x.delivery_status === 'proper'){
                campaignWebsites_copy[index + 1].color = '#34C38F';
            }
            else if (x.delivery_status === 'underdelivery'){
                campaignWebsites_copy[index + 1].color = '#FF3927';
            }
            else {
                campaignWebsites_copy[index + 1].delivery_status = 'unknown'
                campaignWebsites_copy[index + 1].color = '#74788D';
            }
        })
        setCampaignWebsites(campaignWebsites_copy)
    }



    function calculateHeight() {
        if (window.innerWidth < 768)
            setDropdownHeight(($(selectOptionsRef.current).outerHeight() + 16) + 'px')
        else 
            setDropdownHeight('')
    }

    function toggleDropdown() {
        if (!dropdownIsOpen)
            $('body').addClass('overflow-hidden')
        else
            $('body').removeClass('overflow-hidden')
        setDropdownIsOpen(!dropdownIsOpen) // if only this is needed put this inline
    }

    useEffect(() => {
        setLoading('loading')
        setLoadingWebsite('loading')
        setLoadingGraph('loading')
        get(GET_CAMPAIGN, {
            id: props.match.params.id
        })
        .then(response => {
            setCampaignInfo(response)
            setCampaignInfoWebsite({total_ordered_impressions: response.total_ordered_impressions, delivery_rate: response.delivery_rate})
        })
        .catch(error => {
              console.log("GET_CAMPAIGN error")
              console.log(error);
        })

        get(GET_CAMPAIGN_PUBLISHER_ANALYTICS, {
            id: props.match.params.id
        })
        .then(response => {

            // var mock_response = {
            //     'Reader.gr': {
            //         impressions: 3461,
            //         clicks: 142,
            //         interactions: 24,
            //         breakdown: [
            //             {
            //                 "count": 10,
            //                 "_id": {
            //                     "yearMonthDay": "2021-05-21",
            //                     "event": "INTERACTION"
            //                 }
            //             },
            //             {
            //                 "count": 14,
            //                 "_id": {
            //                     "yearMonthDay": "2021-05-20",
            //                     "event": "INTERACTION"
            //                 }
            //             },
            //             {
            //                 "count": 58,
            //                 "_id": {
            //                     "yearMonthDay": "2021-05-21",
            //                     "event": "CLICK"
            //                 }
            //             },
            //             {
            //                 "count": 84,
            //                 "_id": {
            //                     "yearMonthDay": "2021-05-20",
            //                     "event": "CLICK"
            //                 }
            //             },
            //             {
            //                 "count": 1352,
            //                 "_id": {
            //                     "yearMonthDay": "2021-05-21",
            //                     "event": "IMPRESSION"
            //                 }
            //             },
            //             {
            //                 "count": 2109,
            //                 "_id": {
            //                     "yearMonthDay": "2021-05-20",
            //                     "event": "IMPRESSION"
            //                 }
            //             }
            //         ],
            //     },
            //     'gazetta.gr': {
            //         impressions: 3465,
            //         clicks: 150,
            //         interactions: 0,
            //         breakdown: [
            //             {
            //                 "count": 58,
            //                 "_id": {
            //                     "yearMonthDay": "2021-05-21",
            //                     "event": "CLICK"
            //                 }
            //             },
            //             {
            //                 "count": 92,
            //                 "_id": {
            //                     "yearMonthDay": "2021-05-20",
            //                     "event": "CLICK"
            //                 }
            //             },
            //             {
            //                 "count": 1356,
            //                 "_id": {
            //                     "yearMonthDay": "2021-05-21",
            //                     "event": "IMPRESSION"
            //                 }
            //             },
            //             {
            //                 "count": 2109,
            //                 "_id": {
            //                     "yearMonthDay": "2021-05-20",
            //                     "event": "IMPRESSION"
            //                 }
            //             }
            //         ],
            //     },
            //     'google.com': {
            //         impressions: 4465,
            //         clicks: 250,
            //         interactions: 0,
            //         breakdown: [
            //             {
            //                 "count": 58,
            //                 "_id": {
            //                     "yearMonthDay": "2021-05-21",
            //                     "event": "CLICK"
            //                 }
            //             },
            //             {
            //                 "count": 192,
            //                 "_id": {
            //                     "yearMonthDay": "2021-05-20",
            //                     "event": "CLICK"
            //                 }
            //             },
            //             {
            //                 "count": 2356,
            //                 "_id": {
            //                     "yearMonthDay": "2021-05-21",
            //                     "event": "IMPRESSION"
            //                 }
            //             },
            //             {
            //                 "count": 2109,
            //                 "_id": {
            //                     "yearMonthDay": "2021-05-20",
            //                     "event": "IMPRESSION"
            //                 }
            //             }
            //         ],
            //     }
            // }
            // response = mock_response

            setCampaignAnalytics(response)

            var websites = [{ label: "All websites", value: "" }]
            var total = {
                'impressions': 0,
                'clicks': 0,
                'interactions': 0,
                'breakdown': {}
            }
            for (var key in response){
                websites.push({ label: key, value: key })
                if (response[key].impressions)
                    total.impressions += response[key].impressions
                if (response[key].clicks)
                    total.clicks += response[key].clicks
                if (response[key].interactions)
                    total.interactions += response[key].interactions
                response[key].breakdown.map((x) => {
                    if (!total.breakdown[x._id.yearMonthDay])
                        total.breakdown[x._id.yearMonthDay] = {clicks: 0, impressions: 0, interactions: 0}
                    if (x._id.event === 'CLICK')
                        total.breakdown[x._id.yearMonthDay].clicks += x.count
                    else if (x._id.event === 'IMPRESSION')
                        total.breakdown[x._id.yearMonthDay].impressions += x.count
                    else if (x._id.event === 'INTERACTION')
                        total.breakdown[x._id.yearMonthDay].interactions += x.count
                })
            }
            setCampaignWebsites(websites)
            calculageCampaignAnalyticsGraphData(total)
        })
        .catch(error => {
              console.log("GET_CAMPAIGN_PUBLISHER_ANALYTICS error")
              console.log(error);
        })
    }, [])

    function calculageCampaignAnalyticsGraphData(total) {
        let graphData_delivered_impressions = [{name: 'Impressions', data: []}]
        let graphData_interaction_rate = [{name: 'Interactions', data: []}]
        let graphData_ctr = [{name: 'Clicks', data: []}]

        for (var key in total.breakdown){
            let data_time = new Date(key).getTime()
            graphData_delivered_impressions[0].data.push([data_time, total.breakdown[key].impressions || 0])
            graphData_interaction_rate[0].data.push([data_time, total.breakdown[key].interactions || 0])
            graphData_ctr[0].data.push([data_time, total.breakdown[key].clicks || 0])
        }

        graphData_delivered_impressions[0].data.sort((a,b) => a[0] - b[0]);
        graphData_interaction_rate[0].data.sort((a,b) => a[0] - b[0]);
        graphData_ctr[0].data.sort((a,b) => a[0] - b[0]);

        setCampaignAnalyticsGraph({
            clicks: total.clicks,
            impressions: total.impressions,
            interactions: total.interactions,
            delivered_impressions: graphData_delivered_impressions,
            interaction_rate: graphData_interaction_rate,
            ctr: graphData_ctr
        })
        setLoadingGraph('')
    }

    function handleChoice(option, element) {
        setDropdownIsOpen(false)
        if (option.value != selectedOption.value) {
            setLoadingGraph('loading')
            setSelectedOption(option)
            if (option.value === "") {
                // TODO manos total...
                var total = {
                    'impressions': 0,
                    'clicks': 0,
                    'interactions': 0,
                    'breakdown': {}
                }
                for (var key in campaignAnalytics){
                    total.impressions += campaignAnalytics[key].impressions
                    total.clicks += campaignAnalytics[key].clicks
                    total.interactions += campaignAnalytics[key].interactions
                    campaignAnalytics[key].breakdown.map((x) => {
                        if (!total.breakdown[x._id.yearMonthDay]) {
                            total.breakdown[x._id.yearMonthDay] = {clicks: 0, impressions: 0, interactions: 0}
                        }
                        if (x._id.event === 'CLICK') {
                            total.breakdown[x._id.yearMonthDay].clicks += x.count
                        }
                        else if (x._id.event === 'IMPRESSION') {
                            total.breakdown[x._id.yearMonthDay].impressions += x.count
                        }
                        else if (x._id.event === 'INTERACTION') {
                            total.breakdown[x._id.yearMonthDay].interactions += x.count
                        }
                    })
                }
                calculageCampaignAnalyticsGraphData(total)
                setCampaignInfoWebsite({total_ordered_impressions: campaignInfo.total_ordered_impressions, delivery_rate: campaignInfo.delivery_rate})
            }
            else {
    
                let analytics = campaignAnalytics[option.value]
    
                var total = {
                    'impressions': analytics.impressions,
                    'clicks': analytics.clicks,
                    'interactions': analytics.interactions,
                    'breakdown': {}
                }
                analytics.breakdown.map((x) => {
                    if (!total.breakdown[x._id.yearMonthDay]) {
                        total.breakdown[x._id.yearMonthDay] = {clicks: 0, impressions: 0, interactions: 0}
                    }
                    if (x._id.event === 'CLICK') {
                        total.breakdown[x._id.yearMonthDay].clicks += x.count
                    }
                    else if (x._id.event === 'IMPRESSION') {
                        total.breakdown[x._id.yearMonthDay].impressions += x.count
                    }
                    else if (x._id.event === 'INTERACTION') {
                        total.breakdown[x._id.yearMonthDay].interactions += x.count
                    }
                })
                calculageCampaignAnalyticsGraphData(total)
                
                let website_total_ordered_impressions = campaignInfo.total_ordered_impressions
                let website_delivery_rate = campaignInfo.delivery_rate
                if (campaignInfo.adplacements) {
                    for (var i = 0; i < campaignInfo.adplacements.length; i++) {
                        if (campaignInfo.adplacements[i].website_name === option.value) {
                            website_total_ordered_impressions = campaignInfo.adplacements[i].limit;
                            website_delivery_rate = campaignInfo.adplacements[i].delivery_rate;
                            break;
                        }
                    }
                }
                setCampaignInfoWebsite({total_ordered_impressions: website_total_ordered_impressions, delivery_rate: website_delivery_rate})
            }
        }
    }

    return (
        <div className="page-content">
            <Container fluid>
                <a className="backbtn" onClick={() => {window.history.back()}}>Back</a>
                <h2 className="pageTitle">{campaignInfo.name} Reports @ {campaignInfo.brand}</h2>
                <Row className="reports">
                    <Col md={3}>
                        <div className={"mcard mcard-select " + (dropdownIsOpen ? 'isOpen' : '')}>
                            <div className="mcard_header" onClick={toggleDropdown}>
                                <div className="mcard_img"><img src={website_icon}></img></div>
                                Websites
                            </div>
                            <div className='mcard_selection'>{selectedOption.label}</div>
                            <div className="mcard-select_choices_wrapper" style={{height: (dropdownIsOpen ? dropdownHeight : '0')}}>
                                <div className="mcard-select_choices" ref={selectOptionsRef}>
                                    {campaignWebsites.map((x, i) => {
                                        return (
                                        <div key={i} onClick={(e) => {handleChoice(x, e.target)}} data-val={x.value} className={(x.value === selectedOption.value) ? 'active' : ''}>
                                            {x.label} <span style={{color: x.color}}>{x.delivery_status}</span>
                                        </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className="mcard-select_overlay" onClick={toggleDropdown}>
                        </div>
                    </Col>
                    <Col md={9}>
                        <Row>
                            <Col lg={6} className={loading}>
                                <div className="mcard canLoad">
                                    <div className="loader"></div>
                                    <div>Start Date: {formatDate(campaignInfo.run_from)}</div>
                                    <div className="mcard_img"><img src={date_green_icon}></img></div>
                                </div>
                                <div className="mcard canLoad">
                                    <div className="loader"></div>
                                    <div>End Date: {formatDate(campaignInfo.run_to)}</div>
                                    <div className="mcard_img"><img src={date_red_icon}></img></div>
                                </div>
                            </Col>
                            <Col lg={6} className={loadingWebsite}>
                                <div className="mcard canLoad">
                                    <div className="loader"></div>
                                    <div><strong>{campaignInfoWebsite.total_ordered_impressions}</strong> Ordered Impressions</div>
                                    <div className="mcard_img"><img src={ordered_impressions_icon}></img></div>
                                </div>
                                <div className="mcard canLoad">
                                    <div className="loader"></div>
                                    <div><strong>{campaignInfoWebsite.delivery_rate ? Math.round(100 * campaignInfoWebsite.delivery_rate) : 0}%</strong> Delivery Rate</div>
                                    <div className="mcard_img"><img src={delivery_rate_icon}></img></div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4} className={loadingGraph}>
                                <div className="mcard mcard-graph canLoad">
                                    <div className="loader"></div>
                                    <div className="mcard_header">
                                        <div className="mcard_img"><img src={delivered_impressions_icon}></img></div>
                                        Delivered Impressions
                                    </div>
                                    <div className="mcard_body">
                                        <strong>{campaignAnalyticsGraph.impressions}</strong>
                                        Total Impressions
                                    </div>
                                    <div className="mcard_graph">
                                        <div className="smallchart">
                                            <div id="line-chart" className="apex-charts" dir="ltr">
                                                <ReactApexChart
                                                    series={campaignAnalyticsGraph.delivered_impressions}
                                                    options={options}
                                                    type="area"
                                                    height={100}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} className={loadingGraph}>
                                <div className="mcard mcard-graph canLoad">
                                    <div className="loader"></div>
                                    <div className="mcard_header">
                                        <div className="mcard_img"><img src={interaction_rate_icon}></img></div>
                                        Interaction Rate
                                    </div>
                                    <div className="mcard_body">
                                        {campaignAnalyticsGraph.interactions > 0
                                            ?   <React.Fragment>
                                                    <strong>{((100 * campaignAnalyticsGraph.interactions / campaignAnalyticsGraph.impressions) || 0).toFixed(2)}%</strong>
                                                    {campaignAnalyticsGraph.interactions} interactions
                                                </React.Fragment>
                                            :   <React.Fragment>
                                                    <strong style={{color: "transparent"}}>5</strong>
                                                    Not available
                                                </React.Fragment>
                            

                                        }
                                    </div>
                                    {campaignAnalyticsGraph.interactions > 0
                                        ?   <div className="mcard_graph">
                                                <div className="smallchart">
                                                    <div id="line-chart" className="apex-charts" dir="ltr">
                                                        <ReactApexChart
                                                            series={campaignAnalyticsGraph.interaction_rate}
                                                            options={options2}
                                                            type="area"
                                                            height={100}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        :   <div className="mcard_graph" style={{height: "115px"}}></div>
                                    }
                                </div>
                            </Col>
                            <Col lg={4} className={loadingGraph}>
                                <div className="mcard mcard-graph canLoad">
                                    <div className="loader"></div>
                                    <div className="mcard_header">
                                        <div className="mcard_img"><img src={ctr_icon}></img></div>
                                        CTR
                                    </div>
                                    <div className="mcard_body">
                                        <strong>{((100 * campaignAnalyticsGraph.clicks / campaignAnalyticsGraph.impressions) || 0).toFixed(2)}%</strong>
                                        {campaignAnalyticsGraph.clicks} clicks
                                    </div>
                                    <div className="mcard_graph">
                                        <div className="smallchart">
                                            <div id="line-chart" className="apex-charts" dir="ltr">
                                                <ReactApexChart
                                                    series={campaignAnalyticsGraph.ctr}
                                                    options={options3}
                                                    type="area"
                                                    height={100}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    )

}

export default withTranslation()(Reports_madinad)
