import {
    GET_ADTAGS,
    GET_ADTAGS_SUCCESS,
    GET_ADTAGS_FAIL,
} from "./actionTypes"

export const getAdtags = params => ({
    type: GET_ADTAGS,
    payload: params,
})

export const getAdtagsSuccess = data => ({
    type: GET_ADTAGS_SUCCESS,
    payload: data,
})

export const getAdtagsFail = error => ({
    type: GET_ADTAGS_FAIL,
    payload: error,
})