import {
    GET_CAMPAIGNS,
    GET_CAMPAIGNS_SUCCESS,
    GET_CAMPAIGNS_FAIL,
    GET_CAMPAIGN_SUCCESS,
    GET_CAMPAIGN_FAIL,
    GET_CAMPAIGN_ANALYTICS_SUCCESS,
    GET_CAMPAIGN_ANALYTICS_FAIL,
} from "./actionTypes"

const initialState = {
    count: "",
    next: null,
    previous: null,
    results: [],
    error: "",
    campaign: {},
    campaign_error: "",
    campaignAnalytics: {}, // TODO MANOS You do not have permission to perform this action
    campaignAnalytics_error: ""
}

const campaigns = (state = initialState, action) => {
    switch (action.type) {
      case GET_CAMPAIGNS:
        state = { ...state}
        break
      case GET_CAMPAIGNS_SUCCESS:
        state = { ...state, ...action.payload, error: "" }
        break
      case GET_CAMPAIGNS_FAIL:
        state = { ...state, error: action.payload }
        break
      case GET_CAMPAIGN_SUCCESS:
        state = { ...state, campaign: action.payload, error: "" }
        break
      case GET_CAMPAIGN_FAIL:
        state = { ...state, campaign_error: action.payload }
        break
      case GET_CAMPAIGN_ANALYTICS_SUCCESS:
        state = { ...state, campaignAnalytics: action.payload, campaignAnalytics_error: "" }
        break
      case GET_CAMPAIGN_ANALYTICS_FAIL:
        state = { ...state, campaignAnalytics_error: action.payload }
        break
      default:
        state = { ...state }
        break
    }
    return state
  }
  
  export default campaigns