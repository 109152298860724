import { call, put, takeEvery, takeLatest } from "redux-saga/effects"
import { GET_USER, GET_USER_ME, PUT_USER, PATCH_USER, PATCH_USER_SET_PASSWORD } from "./actionTypes"
import { getUserMe, getUserMeSuccess, getUserMeFail } from "./actions"

import { getUserInfo } from "../../../helpers/backend_helper"

function* getUserInformation() {
    try {
        const user_response = yield call(getUserInfo)
        if (user_response && user_response.id)
            yield put(getUserMeSuccess(user_response))
        else
            yield put(getUserMeFail('could not fetch user'))
    } 
    catch (error) {
        yield put(getUserMeFail("Bad response from server"))
    }
}

function* authSaga() {
    yield takeEvery(GET_USER_ME, getUserInformation)
}

export default authSaga