import PropTypes from 'prop-types'
import React, { useEffect, useState } from "react"
import Select from "react-select"

import { Row, Col, FormGroup } from "reactstrap"

//Import mini card widgets
import MiniCards from "./mini-card"

//Icons
import campaign_icon from "../../../assets/images/madinad/campaign_red.svg"
import revenue_icon from "../../../assets/images/madinad/revenue_red.svg"
import pending_icon from "../../../assets/images/madinad/pending_red.svg"
// import overdelivered_icon from "../../../assets/images/madinad/overdelivered.svg"
// import underdelivered_icon from "../../../assets/images/madinad/underdelivered.svg"

import {GET_PUBLISHER_OVERVIEW} from "../../../helpers/url_helper"
import {get} from "../../../helpers/api_helper"

function formatDate(date) {
    var year = date.getFullYear()
    var month = date.getMonth() + 1
    var dt = date.getDate()
    if (dt < 10) 
        dt = '0' + dt
    if (month < 10)
        month = '0' + month
    return year + '-' + month + '-' + dt
}

const today = new Date()
const last_month_from = formatDate(new Date(today.getFullYear(), today.getMonth()-1, 1));
const last_month_to = formatDate(new Date(today.getFullYear(), today.getMonth(), 0));

const ActiveCampaigns = props => {

    const [filters, setFilters] = useState({dt_from: last_month_from, dt_to: last_month_to});
    const [campaignOverview, setCampaignOverview] = useState({active: '', revenue: '', pending: ''});
    const [loading, setLoading] = useState('loading');

    useEffect(() => {
        setLoading('loading');
        get(GET_PUBLISHER_OVERVIEW, filters)
            .then(response => {
                setCampaignOverview({
                    active: response.active_campaigns,
                    pending: response.pending_campaigns,
                    revenue: response.revenue
                })
                setLoading('');
            })
            .catch(error => {
                console.log("Campaign overview error")
                console.error(error);
                setLoading('');
            })
    }, [filters])

    function addFilter(filter) {
        setFilters({...filters, ...filter})
    }

    return (
        <React.Fragment>
        <Row className="mb-4">
            <Col className="custom_title">
                <h4>Campaigns</h4>
                <FormGroup className="select2-container" style={{width: `${7 * props.websites[0].label.length + 40}px`}}>
                    <Select
                    defaultValue={props.websites[0]}
                    onChange={(option)=>{addFilter({website: option.value})}}
                    options={props.websites}
                    classNamePrefix="select2-selection"
                    />
                </FormGroup>
            </Col>
        </Row>
        <Row>
            <Col xl={4} md={6} className={loading}>
                <MiniCards title={['Active Campaigns']} text={[campaignOverview.active]} icon={[campaign_icon]}></MiniCards>
            </Col>
            <Col xl={4} md={6} className={loading}>
                <MiniCards title={['Last month’s Revenue']} text={[(campaignOverview.revenue ? campaignOverview.revenue : 0) + '€']} icon={[revenue_icon]}></MiniCards>
            </Col>
            <Col xl={4} md={6} className={loading}>
                <MiniCards title={['Pending Campaigns']} text={[campaignOverview.pending]} icon={[pending_icon]}></MiniCards>
            </Col>
            {/* <Col xl={3} md={6}>
                <MiniCards title={['Overdelivered', 'Underdelivered']} text={[overdelivered_num, underdelivered_num]} icon={[overdelivered_icon, underdelivered_icon]}></MiniCards>
            </Col> */}
        </Row>
        </React.Fragment>
    )
}


export default ActiveCampaigns