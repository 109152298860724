/* EVENTS */
export const GET_BILLING = "GET_BILLING"
export const GET_BILLING_SUCCESS = "GET_BILLING_SUCCESS"
export const GET_BILLING_FAIL = "GET_BILLING_FAIL"

export const PUT_BILLING = "PUT_BILLING"
export const PUT_BILLING_SUCCESS = "PUT_BILLING_SUCCESS"
export const PUT_BILLING_FAIL = "PUT_BILLING_FAIL"

export const PATCH_BILLING = "PATCH_BILLING"
export const PATCH_BILLING_SUCCESS = "PATCH_BILLING_SUCCESS"
export const PATCH_BILLING_FAIL = "PATCH_BILLING_FAIL"