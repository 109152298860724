import axios from "axios"
import jwt_decode from "jwt-decode";

function tokenIsValid(token) {
  try {
    if (!token) {
      return false;
    }
    let decodedToken = jwt_decode(token);
    let currentDate = new Date();
    // JWT exp is in seconds
    if (decodedToken.exp * 1000 < currentDate.getTime()) {// token expired 
      return false;
    }
  }
  catch (error) {
    return false;
  }
  return true;
}

// function refreshToken() {
//   //debugger;
//   let refresh_token = localStorage.getItem("refreshToken");
//   if (tokenIsValid(refresh_token)) {
//       var response = post('/token/refresh/', {refresh: refresh_token})
//       if (response && response.access && response.refresh) {
//         localStorage.setItem("refreshToken", response.refresh)
//         localStorage.setItem("accessToken", response.access)
//         return true;
//       }
//   }

//   localStorage.removeItem("accessToken")
//   localStorage.removeItem("refreshToken")
//   return false;
// }

function getRefreshToken() {
  let refresh_token = localStorage.getItem("refreshToken")
  if (tokenIsValid(refresh_token)) {
    return refresh_token
  }
  localStorage.removeItem("accessToken")
  localStorage.removeItem("refreshToken")
  return false
}

//apply base url for axios

const API_URL = process.env.REACT_APP_ENV === "production" ? process.env.REACT_APP_APIBASEURL : process.env.REACT_APP_APIBASEURL_DEV

const axiosApi = axios.create({
  baseURL: API_URL,
  // 'Content-Type': 'application/json',
  'Authorization': 'Bearer '
})

axiosApi.interceptors.request.use(function (config) {
  config.headers = {
    ...config.headers,
    'Authorization': 'Bearer ' + localStorage.getItem("accessToken")
  };
  return config;
});

/*******Try guide START*******/

axiosApi.interceptors.response.use(
    function(response) {
      // If the request succeeds, we don't have to do anything and just return the response
      return response
    },
    function(error) {
      if (isTokenExpiredError(error.response)) {
        return resetTokenAndReattemptRequest(error)
      }
      // If the error is due to other reasons, we just throw it back to axios
      return Promise.reject(error)
    }
);

function isTokenExpiredError(errorResponse) {
  // Your own logic to determine if the error is due to JWT token expired returns a boolean value
  if (tokenIsValid(localStorage.getItem("accessToken")))
      return false
  return true;
}

let isAlreadyFetchingAccessToken = false;

// This is the list of waiting requests that will retry after the JWT refresh complete
let subscribers = [];

function onAccessTokenFetched(access_token) {
	// When the refresh is successful, we start retrying the requests one by one and empty the queue
  subscribers.forEach(callback => callback(access_token));
  subscribers = [];
}

function addSubscriber(callback) {
  subscribers.push(callback);
}

async function resetTokenAndReattemptRequest(error) {
  // console.log("reset token and reattempt");
  try {
    const { response: errorResponse } = error;
    const resetToken = getRefreshToken(); // Your own mechanism to get the refresh token to refresh the JWT token
    if (!resetToken) {
      // We can't refresh, throw the error anyway
      var formattedError = {status: "", message: ""};
      if (errorResponse.status)
        formattedError.status = errorResponse.status;
      if (errorResponse.data && errorResponse.data.detail)
        formattedError.message = errorResponse.data.detail;
      return Promise.reject(formattedError);
    }
    /* Proceed to the token refresh procedure
    We create a new Promise that will retry the request,
    clone all the request configuration from the failed
    request in the error object. */
    const retryOriginalRequest = new Promise(resolve => {
    /* We need to add the request retry to the queue
    since there another request that already attempt to
    refresh the token */
      addSubscriber(access_token => {
        errorResponse.config.headers.Authorization = 'Bearer ' + access_token;
        resolve(axios(errorResponse.config));
      });
    });
    if (!isAlreadyFetchingAccessToken) {
      isAlreadyFetchingAccessToken = true;
      const response = await axios({
        method: 'POST',
        url: API_URL + '/token/refresh/',
        headers: {
          // 'Content-Type': 'application/json',
          'Authorization': 'Bearer '
        },
        data: {
          refresh: resetToken
        }
      });
      if (!response.data) {
        return Promise.reject(error);
      }
      // console.log("Refreshed token successfully...resuming request execution");
      let accessToken = response.data.access
      localStorage.setItem("refreshToken", response.data.refresh)
      localStorage.setItem("accessToken", response.data.access)
      isAlreadyFetchingAccessToken = false;
      onAccessTokenFetched(accessToken);
    }
    return retryOriginalRequest;
  } catch (err) {
    return Promise.reject(err);
  }
}

/*******Try END*******/

export async function get(url, params = {}) {
  url = putIdInUrl(url, params)
  return await axiosApi
    .get(url, { params })
    .then(response => response.data)
}

export async function post(url, data, params = {}) {
  url = putIdInUrl(url, params)
  return axiosApi
    .post(url, data, { params })
    .then(response => response.data)
}

export async function put(url, data, params = {}) {
  url = putIdInUrl(url, params)
  return axiosApi
    .put(url, data, { params })
    .then(response => response.data)
}

export async function patch(url, data, params = {}) {
  url = putIdInUrl(url, params)
  return axiosApi
    .patch(url, data , { params })
    .then( response => response.data)
}

export async function del(url, params = {}) {
  url = putIdInUrl(url, params)
  return axiosApi
    .patch(url, { params })
    .then( response => response.data)
}

function putIdInUrl(url, params) {
  if (url.includes(':id')) {
    if ('id' in params && params.id) {
      url = url.replace(":id", params.id)
      delete params.id;
    }
    else
      throw 'ID is missing'
  }
  return url;
}
