import {
    GET_WEBSITES,
    GET_WEBSITES_SUCCESS,
    GET_WEBSITES_FAIL,
    GET_WEBSITE,
    GET_WEBSITE_SUCCESS,
    GET_WEBSITE_FAIL
} from "./actionTypes"

export const getWebsites = () => ({
    type: GET_WEBSITES,
})

export const getWebsitesSuccess = data => ({
    type: GET_WEBSITES_SUCCESS,
    payload: data,
})

export const getWebsitesFail = error => ({
    type: GET_WEBSITES_FAIL,
    payload: error,
})

export const getWebsite = id => ({
    type: GET_WEBSITE,
    payload: id
})

export const getWebsiteSuccess = data => ({
    type: GET_WEBSITE_SUCCESS,
    payload: data,
})

export const getWebsiteFail = error => ({
    type: GET_WEBSITE_FAIL,
    payload: error,
})