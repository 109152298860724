import { call, put, takeEvery, takeLatest } from "redux-saga/effects"
import { GET_BILLING, PUT_BILLING, PATCH_BILLING } from "./actionTypes"
import { getBillingSuccess, getBillingFail, putBillingSuccess, putBillingFail, patchBillingSuccess, patchBillingFail } from "./actions"

import { getBilling, putBilling, patchBilling } from "../../../helpers/backend_helper"

function* getBillingInfo(params) {
    try {
        const response = yield call(getBilling, params.payload)
        if (response)
            yield put(getBillingSuccess(response))
        else
            yield put(getBillingFail('could not fetch billing'))
    }
    catch (error) {
        yield put(getBillingFail("Bad response from server"))
    }
}

function* putBillingInfo(data) {
    try {
        const response = yield call(putBilling, data.payload.data, data.payload.config)
        if (response)
            yield put(putBillingSuccess(response))
        else
            yield put(putBillingFail('could not put billing'))
    } 
    catch(error) {
        yield put(putBillingFail("Bad response from server"))
    }
}

function* patchBillingInfo(data) {
    try {
        const response = yield call(patchBilling, data.payload.data, data.payload.config)
        if (response)
            yield put(patchBillingSuccess(response))
        else
            yield put(patchBillingFail('could not patch billing'))
    } 
    catch(error) {
        yield put(patchBillingFail("Bad response from server"))
    }
}

function* billingSagas() {
    yield takeEvery(GET_BILLING, getBillingInfo)
    yield takeEvery(PUT_BILLING, putBillingInfo)
    yield takeEvery(PATCH_BILLING, patchBillingInfo)
}

export default billingSagas