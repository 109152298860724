import PropTypes from 'prop-types'
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"

import {
    Card,
    CardBody,
    CardTitle,
    Col,
    Container,
    Media,
    Row,
    Table,
    FormGroup
} from "reactstrap"

import Select from "react-select"

// Redux
import { connect } from "react-redux"

// Redux Store
import {
    getCampaigns,
    getWebsites
} from "../../../store/actions"

//Import Components
import ActiveCampaigns from "./active-campaigns.js"
import DataCharts from "./data-charts.js"

let options_websites = [
    { label: "All Websites", value: "" }
]
const Dashboard_madinad = props => {
    // console.log("~~~~~~~~~~~~dashboard renders once")
    const [websites, setWebsites] = useState([ { label: "All Websites", value: "" } ])
    useEffect(() => {
        // if (!props.websites.results)
            props.getCampaigns()
        // if (!props.campaigns.results)
            props.getWebsites()
    }, [])

    useEffect(() => {
        options_websites = [ { label: "All Websites", value: "" } ]
        props.websites.results.forEach((x) => {
            options_websites.push({label: x.name, value: x.id});
        });
        // if (options_websites.length > 1)
            setWebsites(options_websites)
    }, [props.websites.results])


    return (
        <div className="page-content">
            <Container fluid>
                <div className="text-muted mb-4">
                    <h5 className="mb-1">Welcome, { (props.user.first_name) ? props.user.first_name : props.user.username}</h5>
                    <p className="mb-0">Take a quick look at last months’ data for {props.publisher}</p>
                </div>
                <ActiveCampaigns campaigns={props.campaigns} websites={websites}/>
                <DataCharts campaigns={props.campaigns} websites={websites}></DataCharts>
            </Container>
        </div>
    )
}

// optionalArray: PropTypes.array,
// optionalBool: PropTypes.bool,
// optionalFunc: PropTypes.func,
// optionalNumber: PropTypes.number,
// optionalObject: PropTypes.object,
// optionalString: PropTypes.string,
// optionalSymbol: PropTypes.symbol,
// optionalAny: PropTypes.any

Dashboard_madinad.propTypes = {
    username: PropTypes.object,
    getCampaigns: PropTypes.func,
    getWebsites: PropTypes.func,
}

const mapStateToProps = state => {
    return { 
        user: state.User,
        publisher: state.User.publisher,
        campaigns: state.campaigns,
        websites: state.websites
    }
}

export default connect(mapStateToProps, {getCampaigns, getWebsites})(withTranslation()(Dashboard_madinad))