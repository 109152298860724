import {
    GET_BILLING,
    GET_BILLING_SUCCESS,
    GET_BILLING_FAIL,
    PUT_BILLING,
    PUT_BILLING_SUCCESS,
    PUT_BILLING_FAIL,
    PATCH_BILLING,
    PATCH_BILLING_SUCCESS,
    PATCH_BILLING_FAIL,
} from "./actionTypes"

export const getBilling = params => ({
    type: GET_BILLING,
    payload: params,
})

export const getBillingSuccess = data => ({
    type: GET_BILLING_SUCCESS,
    payload: data,
})

export const getBillingFail = error => ({
    type: GET_BILLING_FAIL,
    payload: error,
})

export const putBilling = (data, config) => ({
    type: PUT_BILLING,
    payload: {data, config}
})

export const putBillingSuccess = data => ({
    type: PUT_BILLING_SUCCESS,
    payload: data,
})

export const putBillingFail = error => ({
    type: PUT_BILLING_FAIL,
    payload: error,
})

export const patchBilling = (data, config) => ({
    type: PATCH_BILLING,
    payload: {data, config}
})

export const patchBillingSuccess = data => ({
    type: PATCH_BILLING_SUCCESS,
    payload: data,
})

export const patchBillingFail = error => ({
    type: PATCH_BILLING_FAIL,
    payload: error,
})