import PropTypes from 'prop-types'
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"

import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import SweetAlert from "react-bootstrap-sweetalert"

import {
    Card,
    CardBody,
    CardTitle,
    Col,
    Container,
    Media,
    Row,
    FormGroup
} from "reactstrap"

import Select from "react-select"

// Redux
import { connect } from "react-redux"

// Redux Store
import {
    getWebsites,
    getBilling,
    putBilling,
    patchBilling,
} from "../../../store/actions"

//Import Components
// import ActiveCampaigns from "./active-campaigns.js"
// import DataCharts from "./data-charts.js"

// let options_websites = [{ label: "Website", value: "" }]
const Billing_madinad = props => {

    // const [tableFilters, setTableFilters] = useState({limit: 2});
    const [loading, setLoading] = useState('loading');
    const [deleteInvoiceAlert, setDeleteInvoiceAlert] = useState({showAlert: false, id: ''})
    const [tableFilters, setTableFilters] = useState({limit: 25});
    const [columnOrder, setColumnOrder] = useState({campaign: '', website: '', budget: '', billing_date: '', advertisement_order_sent: '', publisher_invoice: '', payment_sent_to_publisher: ''});

    useEffect(() => {
        props.getWebsites()
    }, [])

    useEffect(() => {
        setLoading('loading')
        props.getBilling(tableFilters)
    }, [tableFilters, props.billings.success_patch])

    useEffect(() => {
        setLoading('')
    }, [props.billings.results])

    function changeOrder(key, order) {

        if (columnOrder[key] === "asc") {
            if (order === "asc")
                columnOrder[key] = ''
            else
                columnOrder[key] = 'desc'
        }
        else if (columnOrder[key] === "desc") {
            if (order === "desc")
                columnOrder[key] = ''
            else
                columnOrder[key] = 'asc'
        }
        else {
            if (order === "desc")
                columnOrder[key] = 'desc'
            else
                columnOrder[key] = 'asc'
        }
        setColumnOrder({...columnOrder})
        var ordering = ''
        var hasOne = false;
        Object.entries(columnOrder).map(([key, value]) => {
            if (value === 'asc') {
                ordering += (hasOne ? ',' : '') + key
                hasOne = true
            }
            else if (value === 'desc') {
                ordering += (hasOne ? ',' : '') + '-' + key
                hasOne = true
            }
                
        })
        addFilter({ordering})
    }

    let options_billingMonth = [
        {label: "Billing month", value: ""},
        {label: "Last month", value: "last_month"},
        {label: "Last 3 months", value: "last_3_months"},
        {label: "Last 6 months", value: "last_6_months"},
        {label: "Current Year", value: "current_year"},
        {label: "Last Year", value: "last_year"},
        {label: "Last 3 Year", value: "last_3_years"},
        {label: "Last 10 Year", value: "last_10_years"},
    ]

    let options_order = [
        {label: "Order", value: ''},
        {label: "Ordered", value: true},
        {label: "Unordered", value: false},
    ]

    let options_invoice = [
        {label: "Invoice", value: ""},
        {label: "Invoiced", value: true},
        {label: "Uninvoiced", value: false},
    ]

    // TODO manos the bellow will probably contain more options
    // since unpaid needs a string value instead of just false
    let options_payment = [
        {label: "Payment", value: ""},
        {label: "Paid", value: true},
        {label: "Unpaid", value: 'unpaid'},
    ]

    // useEffect(() => {
    //     options_websites = [ { label: "Website", value: "" } ]
    //     props.websites.forEach((x) => {
    //         options_websites.push({label: x.name, value: x.name});
    //     });
    // }, [props.websites])

    function handleSelectedOptionBillingMonth(option) {
        var filter = {
            billing_from: "",
            billing_to: ""
        }
        var today = new Date()
        if (option.value === "last_month") {
            filter.billing_from = new Date(today.getFullYear(), today.getMonth()-1, 1);
            filter.billing_to = new Date(today.getFullYear(), today.getMonth(), 0);
        }
        else if (option.value === "last_3_months") {
            filter.billing_from = new Date(today.getFullYear(), today.getMonth()-3, 1);
            filter.billing_to = new Date(today.getFullYear(), today.getMonth(), 0);
        }
        else if (option.value === "last_6_months") {
            filter.billing_from = new Date(today.getFullYear(), today.getMonth()-6, 1);
            filter.billing_to = new Date(today.getFullYear(), today.getMonth(), 0);
        }
        else if (option.value === "current_year") {
            filter.billing_from = new Date(today.getFullYear(), 0, 1);
            filter.billing_to = today
        }
        else if (option.value === "last_year") {
            filter.billing_from = new Date(today.getFullYear()-1, 0, 1);
            filter.billing_to = new Date(today.getFullYear()-1, 11, 31);
        }
        else if (option.value === "last_3_years") {
            filter.billing_from = new Date(today.getFullYear()-3, 0, 1);
            filter.billing_to = new Date(today.getFullYear()-1, 11, 31);
        }
        else if (option.value === "last_10_years") {
            filter.billing_from = new Date(today.getFullYear()-10, 0, 1);
            filter.billing_to = new Date(today.getFullYear()-1, 11, 31);
        }
        if (option.value) {
            filter.billing_from = formatDate(filter.billing_from)
            filter.billing_to = formatDate(filter.billing_to)
        }
        addFilter(filter)
    }

    function paginationPrevNext (url) {
        var rgx = /offset=[\d]+/
        var offset = rgx.exec(url)
        if (offset)
            offset = offset[0].split('=')[1]
        else
            offset = 0
        
        addFilter({offset})
    }

    function formatDate(date, format) {
        if (format === 'YYYY/mm') { // In this occasion it cannot be converted to date
            var splitted = date.split(' ');
            return splitted[1] + '/' + splitted[0]
        }
        var year = date.getFullYear()
        var month = date.getMonth() + 1
        var dt = date.getDate()
        if (dt < 10) 
            dt = '0' + dt
        if (month < 10)
            month = '0' + month
        return year + '-' + month + '-' + dt
    }

    function addFilter(filter) {
        setTableFilters({...tableFilters, ...filter})
    }

    function onFileChange(e) {
        const formData = new FormData();
        const config = { id: $(e.target).closest('[data-id]').attr('data-id')}
        formData.append("publisher_invoice", e.target.files[0]);
        setLoading('loading')
        props.patchBilling(formData, config);
    }

    var searchTimeout
    function onSearchChange(val) {
        clearTimeout(searchTimeout);
        searchTimeout  =  setTimeout(function() {
            addFilter({campaign_name: val})
        }, 300);
    }

    function deleteInvoice(id) {
        const config = {id: id}
        const formData = new FormData();
        formData.append("publisher_invoice", '');
        props.patchBilling(formData, config);
    }

    return (
        <div className="page-content">
            <Container fluid>
                {deleteInvoiceAlert.showAlert ? (
                    <SweetAlert
                      title="Αφαίρεση Τιμολογίου"
                      warning
                      showCancel
                      confirmBtnBsStyle="success"
                      cancelBtnBsStyle="danger"
                      confirmBtnText="Eπιθυμώ Αφαίρεση"
                      cancelBtnText="Ακύρωση"
                      onConfirm={() => {
                        deleteInvoice(deleteInvoiceAlert.id)
                        setDeleteInvoiceAlert({showAlert: false, id: ''})
                      }}
                      onCancel={() => {
                        setDeleteInvoiceAlert({showAlert: false, id: ''})
                      }}
                    >
                    Είστε σίγουρος ότι θέλετε να αφαιρέσετε το τιμολόγιο;
                    </SweetAlert>
                  ) : null}
                <h2 className="pageTitle">Billing</h2>
                <Card className={loading}>
                    <CardBody className="canLoad">
                    <div className="loader"></div>
                    <div className="table_filters d-flex justify-content-between flex-wrap">
                        <div className="table_filters_search">
                            <input onChange={(e) => {onSearchChange(e.target.value)}} placeholder="Search Campaign Name"></input>
                            <span></span>
                        </div>
                        <div className="d-flex flex-wrap table_filters_selects">
                            <FormGroup className={"select2-container " + (!('billing_from' in tableFilters) || tableFilters.billing_from === '' ? '' : 'active')} style={{width: `${7 * options_billingMonth[0].label.length + 40}px`}}>
                                <Select
                                defaultValue={options_billingMonth[0]}
                                onChange={handleSelectedOptionBillingMonth}
                                options={options_billingMonth}
                                classNamePrefix="select2-selection"
                                isSearchable={false}
                                />
                            </FormGroup>
                            <FormGroup className={"select2-container " + (!('advertisement_order_sent' in tableFilters) || tableFilters.advertisement_order_sent === '' ? '' : 'active')} style={{width: `${7 * options_order[0].label.length + 40}px`}}>
                                <Select
                                defaultValue={options_order[0]}
                                onChange={(option)=>{addFilter({advertisement_order_sent: option.value})}}
                                options={options_order}
                                classNamePrefix="select2-selection"
                                isSearchable={false}
                                />
                            </FormGroup>
                            <FormGroup className={"select2-container " + (!('invoiced' in tableFilters) || tableFilters.invoiced === '' ? '' : 'active')} style={{width: `${7 * options_invoice[0].label.length + 40}px`}}>
                                <Select
                                defaultValue={options_invoice[0]}
                                onChange={(option)=>{addFilter({invoiced: option.value})}}
                                options={options_invoice}
                                classNamePrefix="select2-selection"
                                isSearchable={false}
                                />
                            </FormGroup>
                            <FormGroup className={"select2-container " + (!('payment_sent_to_publisher' in tableFilters) || tableFilters.payment_sent_to_publisher === '' ? '' : 'active')} style={{width: `${8 * options_payment[0].label.length + 40}px`}}>
                                <Select
                                defaultValue={options_payment[0]}
                                onChange={(option)=>{addFilter({payment_sent_to_publisher: option.value})}}
                                options={options_payment}
                                classNamePrefix="select2-selection"
                                isSearchable={false}
                                />
                            </FormGroup>
                        </div>
                    </div>
                    <div className="table-rep-plugin">
                        <div
                        className="table-responsive mb-0"
                        data-pattern="priority-columns"
                        >
                        <Table
                            id="tech-companies-1"
                            className="table table-striped table-bordered"
                        >
                            <Thead>
                            <Tr>
                                <Th><div className={"tableCell " + columnOrder.campaign}>
                                        <span onClick={() => {changeOrder('campaign')}}>Campaign</span>
                                        <div className="order"><span className="order_asc" onClick={() => {changeOrder('campaign','asc')}}></span><span className="order_desc" onClick={() => {changeOrder('campaign','desc')}}></span></div>
                                    </div>
                                </Th>
                                <Th data-priority="1"><div className={"tableCell " + columnOrder.website}>
                                    <span onClick={() => {changeOrder('website')}}>Website</span>
                                    <div className="order"><span className="order_asc" onClick={() => {changeOrder('website','asc')}}></span><span className="order_desc" onClick={() => {changeOrder('website','desc')}}></span></div></div>
                                </Th>
                                <Th data-priority="3"><div className={"tableCell " + columnOrder.budget}>
                                    <span onClick={() => {changeOrder('budget')}}>Budget</span>
                                    <div className="order"><span className="order_asc" onClick={() => {changeOrder('budget', 'asc')}}></span><span className="order_desc" onClick={() => {changeOrder('budget','desc')}}></span></div></div>
                                </Th>
                                <Th data-priority="1"><div className={"tableCell " + columnOrder.billing_date}>
                                    <span onClick={() => {changeOrder('campaign__run_to')}}>Billing Date</span>
                                    <div className="order"><span className="order_asc" onClick={() => {changeOrder('campaign__run_to','asc')}}></span><span className="order_desc" onClick={() => {changeOrder('campaign__run_to','desc')}}></span></div></div>
                                </Th>
                                <Th data-priority="3"><div className={"tableCell " + columnOrder.advertisement_order_sent}>
                                    <span onClick={() => {changeOrder('advertisement_order_sent')}}>Order</span>
                                    <div className="order"><span className="order_asc" onClick={() => {changeOrder('advertisement_order_sent','asc')}}></span><span className="order_desc" onClick={() => {changeOrder('advertisement_order_sent','desc')}}></span></div></div>
                                </Th>
                                <Th data-priority="3"><div className={"tableCell " + columnOrder.publisher_invoice}>
                                    <span onClick={() => {changeOrder('publisher_invoice')}}>Invoice</span>
                                    <div className="order"><span className="order_asc" onClick={() => {changeOrder('publisher_invoice','asc')}}></span><span className="order_desc" onClick={() => {changeOrder('publisher_invoice','desc')}}></span></div></div>
                                </Th>
                                <Th data-priority="6"><div className={"tableCell " + columnOrder.payment_sent_to_publisher}>
                                    <span onClick={() => {changeOrder('payment_sent_to_publisher')}}>Payment</span>
                                    <div className="order"><span className="order_asc" onClick={() => {changeOrder('payment_sent_to_publisher','asc')}}></span><span className="order_desc" onClick={() => {changeOrder('payment_sent_to_publisher','desc')}}></span></div></div>
                                </Th>
                            </Tr>
                            </Thead>
                            <Tbody>
                            {props.billings.results.map((x, i) => {
                            return (
                                <Tr key={i} data-id={x.id}>
                                    <Th>{x.campaign.name}</Th>
                                    <Td><a href={x.website.url} target="_blank">{x.website.name}</a></Td>
                                    <Td>{x.budget}€</Td>
                                    <Td>{formatDate(x.billing_date, 'YYYY/mm', true)}</Td>
                                    <Td>
                                        {x.advertisement_order ? (
                                            <div className="tableCell"><span className="green">Ordered</span> <div><a className="table_icon download" href={x.advertisement_order} target="_blank" download></a></div></div>
                                            ) : (
                                            <div className="tableCell"><span className="red">Unordered</span></div>
                                        )}
                                    </Td>
                                    <Td>
                                        {x.publisher_invoice ? (
                                            <div className="tableCell"><span className="green">Invoiced</span> <div><a className="table_icon document" href={x.publisher_invoice} target="_blank"></a><a onClick={()=> {setDeleteInvoiceAlert({showAlert: true, id: x.id})}} className="table_icon delete"></a></div></div>
                                            ) : (
                                            <div className="tableCell">
                                                <span className="red">Uninvoiced</span>
                                                <div>
                                                    <label htmlFor={"upload" + i} className="table_upload">Upload</label>
                                                    <input className="d-none" id={"upload" + i} onChange={onFileChange} type="file"></input>
                                                </div>
                                            </div>
                                        )}
                                    </Td>
                                    <Td>
                                        {x.payment_sent_to_publisher ? (
                                            <span className="green">Paid</span>
                                            ) : (
                                            <span className="red">Unpaid</span>
                                        )}
                                    </Td>
                                </Tr>
                            )
                            })}
                            </Tbody>
                        </Table>
                        </div>
                    </div>
                    {props.billings.results.length > 0 && props.billings.results.length < props.billings.count && (
                        [1].map(() => {
                            return (
                                <div key="1" className="pagination">
                                    <span className={props.billings.previous ? 'active': ''} onClick={() => {paginationPrevNext(props.billings.previous)}}></span>
                                    {[...Array(Math.ceil(props.billings.count/props.billings.results.length)).keys()].map((x, i) => {
                                        return (
                                            <a className={tableFilters.offset == i * tableFilters.limit || i === 0 && !tableFilters.offset ? 'active' : ''} key={i} onClick={()=> {addFilter({offset: i * tableFilters.limit})}}>{i + 1}</a>
                                        )
                                    })}
                                    <span className={props.billings.next ? 'active': ''} onClick={() => {paginationPrevNext(props.billings.next)}}></span>
                                </div>
                            )
                        })
                    )}
                    {props.billings.error && (
                        <span className="error">{props.billings.error}</span>
                    )}
                  </CardBody>  
                </Card>
            </Container>
        </div>
    )

}

// optionalArray: PropTypes.array,
// optionalBool: PropTypes.bool,
// optionalFunc: PropTypes.func,
// optionalNumber: PropTypes.number,
// optionalObject: PropTypes.object,
// optionalString: PropTypes.string,
// optionalSymbol: PropTypes.symbol,
// optionalAny: PropTypes.any

Billing_madinad.propTypes = {
    getWebsites: PropTypes.func,
    getBilling: PropTypes.func,
    putBilling: PropTypes.func,
    patchBilling: PropTypes.func,
}

const mapStateToProps = state => {
    return { 
        websites: state.websites.results,
        publisher: state.User.publisher,
        billings: state.billings
    }
}

export default connect(mapStateToProps, {getWebsites, getBilling, putBilling, patchBilling})(withTranslation()(Billing_madinad))