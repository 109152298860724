import {
    GET_CAMPAIGNS,
    GET_CAMPAIGNS_SUCCESS,
    GET_CAMPAIGNS_FAIL,
    GET_CAMPAIGN,
    GET_CAMPAIGN_SUCCESS,
    GET_CAMPAIGN_FAIL,
    GET_CAMPAIGN_ANALYTICS,
    GET_CAMPAIGN_ANALYTICS_SUCCESS,
    GET_CAMPAIGN_ANALYTICS_FAIL,
} from "./actionTypes"

export const getCampaigns = params => ({
    type: GET_CAMPAIGNS,
    payload: params,
})

export const getCampaignsSuccess = data => ({
    type: GET_CAMPAIGNS_SUCCESS,
    payload: data,
})

export const getCampaignsFail = error => ({
    type: GET_CAMPAIGNS_FAIL,
    payload: error,
})

export const getCampaign = id => ({
    type: GET_CAMPAIGN,
    payload: id
})

export const getCampaignSuccess = data => ({
    type: GET_CAMPAIGN_SUCCESS,
    payload: data,
})

export const getCampaignFail = error => ({
    type: GET_CAMPAIGN_FAIL,
    payload: error,
})

export const getCampaignAnalytics = id => ({
    type: GET_CAMPAIGN_ANALYTICS,
    payload: id
})

export const getCampaignAnalyticsSuccess = data => ({
    type: GET_CAMPAIGN_ANALYTICS_SUCCESS,
    payload: data,
})

export const getCampaignAnalyticsFail = error => ({
    type: GET_CAMPAIGN_ANALYTICS_FAIL,
    payload: error,
})