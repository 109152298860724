import PropTypes from 'prop-types'
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"

import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"

import {
    Card,
    CardBody,
    CardTitle,
    Col,
    Container,
    Media,
    Row,
    FormGroup
} from "reactstrap"

import Select from "react-select"

// Redux
import { connect } from "react-redux"

// Redux Store
import {
    getWebsites,
    getAdtags
} from "../../../store/actions"

//Import Components
// import ActiveCampaigns from "./active-campaigns.js"
// import DataCharts from "./data-charts.js"

let options_websites = [{ label: "Website", value: "" }]
const Adtags_madinad = props => {
    //console.log("~~~~~~~~~~~~~~~~~~~BILLING RERENDERS")
    //console.log("campaign id = ", props.match.params.id)

    const [loading, setLoading] = useState('loading');
    const [tableFilters, setTableFilters] = useState({limit: 2});
    // const [tableFilters, setTableFilters] = useState({});
    const [columnOrder, setColumnOrder] = useState({website: ''});

    useEffect(() => {
        if (!props.websites.length)
            props.getWebsites()
        // props.getCampaigns()
    }, [])

    useEffect(() => {
        setLoading('loading')
        props.getAdtags({campaign_id: props.match.params.id ,...tableFilters})
    }, [tableFilters])

    useEffect(() => {
        setLoading('')
    }, [props.adtags.results])

    //console.log("~~~~table filters = ", tableFilters)

    function changeOrder(key, order) {

        if (columnOrder[key] === "asc") {
            if (order === "asc")
                columnOrder[key] = ''
            else
                columnOrder[key] = 'desc'
        }
        else if (columnOrder[key] === "desc") {
            if (order === "desc")
                columnOrder[key] = ''
            else
                columnOrder[key] = 'asc'
        }
        else {
            if (order === "desc")
                columnOrder[key] = 'desc'
            else
                columnOrder[key] = 'asc'
        }
        setColumnOrder({...columnOrder})
        //console.log(columnOrder);
        var ordering = ''
        var hasOne = false;
        Object.entries(columnOrder).map(([key, value]) => {
            if (value === 'asc') {
                ordering += (hasOne ? ',' : '') + key
                hasOne = true
            }
            else if (value === 'desc') {
                ordering += (hasOne ? ',' : '') + '-' + key
                hasOne = true
            }
                
        })
        addFilter({ordering})
    }

    let options_startDate = [
        {label: "Start Date", value: ""},
        {label: "Last month", value: "last_month"},
        {label: "Before 3 months", value: "before_3_months"},
        {label: "Before 6 months", value: "before_6_months"},
        {label: "Last Year", value: "last_year"},
    ]

    let options_endDate = [
        {label: "Start Date", value: ""},
        {label: "Last month", value: "last_month"},
        {label: "Before 3 months", value: "before_3_months"},
        {label: "Before 6 months", value: "before_6_months"},
        {label: "Last Year", value: "last_year"},
    ]

    let options_status = [
        {label: "Status", value: ''},
        {label: "Active", value: 'active'},
        {label: "Completed", value: 'compelted'},
        {label: "Pending", value: 'pending'},
    ]

    useEffect(() => {
        options_websites = [ { label: "Website", value: "" } ]
        props.websites.forEach((x) => {
            options_websites.push({label: x.name, value: x.id});
        });
    }, [props.websites])

    function handleSelectedOptionBillingMonth(option) {
        //console.log("options billing month");
        var filter = {
            billing_from: "",
            billing_to: ""
        }
        var today = new Date()
        if (option.value === "last_month") {
            filter.billing_from = new Date(today.getFullYear(), today.getMonth()-1, 1);
            filter.billing_to = new Date(today.getFullYear(), today.getMonth(), 0);
        }
        else if (option.value === "last_3_months") {
            filter.billing_from = new Date(today.getFullYear(), today.getMonth()-3, 1);
            filter.billing_to = new Date(today.getFullYear(), today.getMonth(), 0);
        }
        else if (option.value === "last_6_months") {
            filter.billing_from = new Date(today.getFullYear(), today.getMonth()-6, 1);
            filter.billing_to = new Date(today.getFullYear(), today.getMonth(), 0);
        }
        else if (option.value === "current_year") {
            filter.billing_from = new Date(today.getFullYear(), 0, 1);
            filter.billing_to = today
        }
        else if (option.value === "last_year") {
            filter.billing_from = new Date(today.getFullYear()-1, 0, 1);
            filter.billing_to = new Date(today.getFullYear()-1, 11, 31);
        }
        else if (option.value === "last_3_years") {
            filter.billing_from = new Date(today.getFullYear()-3, 0, 1);
            filter.billing_to = new Date(today.getFullYear()-1, 11, 31);
        }
        else if (option.value === "last_10_years") {
            filter.billing_from = new Date(today.getFullYear()-10, 0, 1);
            filter.billing_to = new Date(today.getFullYear()-1, 11, 31);
        }
        if (option.value) {
            filter.billing_from = formatDate(filter.billing_from)
            filter.billing_to = formatDate(filter.billing_to)
        }
        addFilter(filter)
    }

    function handleSelectedOptionStartDate(option) {
        //addFilter({start_date: val})
    }

    function handleSelectedOptionEndDate(option) {
        //addFilter({end_date: val})
    }

    function paginationPrevNext (url) {
        var rgx = /offset=[\d]+/
        var offset = rgx.exec(url)
        if (offset)
            offset = offset[0].split('=')[1]
        else
            offset = 0
        
        addFilter({offset})
    }

    function formatDate(date) {
        var year = date.getFullYear()
        var month = date.getMonth() + 1
        var dt = date.getDate()
        if (dt < 10) 
            dt = '0' + dt
        if (month < 10)
            month = '0' + month
        return year + '-' + month + '-' + dt
    }

    function addFilter(filter) {
        //console.log("addfilter = ", filter);
        setTableFilters({...tableFilters, ...filter})
    }

    var searchTimeout
    function onSearchChange(val) {
        clearTimeout(searchTimeout);
        searchTimeout  =  setTimeout(function() {
            addFilter({name: val})
        }, 300);
    }

    function findWebsiteById(id) {
        var websites_length = props.websites.length
        var websiteName = ""
        var i = 0
        while (i < websites_length) {
            if (props.websites[i].id === id)
                websiteName = props.websites[i].name
            i++
        }
        return websiteName
    }

    function copyScript(adtagId, websiteId, element) {
        element.classList.remove('active')
        setTimeout(() => {
            element.classList.add('active')
        }, 200);
        var text_to_copy = "<script>\n" +
        "var iFrame;\n" +
        "if (window.frameElement) {\n" +
        "iFrame = window.parent.document.getElementById(window.frameElement.id)\n" +
        "} else { iFrame = document.body };\n" +
        "var script = document.createElement('script');\n" +
        "script.id = 'madinad-sdk';\n" +
        "script.src = 'https://static.madinad.com/static/madinad-sdk.min.js?adtag=" + adtagId + "&a=" + websiteId + "';\n" +
        "iFrame.parentNode.appendChild(script);\n" +
        "<\/script>\n"

        navigator.clipboard.writeText(text_to_copy)
    }

    return (
        <div className="page-content">
            <Container fluid>
                <a className="backbtn" onClick={() => {window.history.back()}}>Back</a>
                <h2 className="pageTitle">Ad tags</h2>
                <Card className={loading}>
                    <CardBody className="canLoad">
                    <div className="loader"></div>
                    <div className="table-rep-plugin">
                        <div
                        className="table-responsive mb-0"
                        data-pattern="priority-columns"
                        >
                        <Table
                            id="tech-companies-1"
                            className="table table-striped table-bordered"
                        >
                            <Thead>
                            <Tr>
                                <Th>
                                    <div className={"tableCell " + columnOrder.website}>
                                        <span onClick={() => {changeOrder('website')}}>Website</span>
                                        <div className="order"><span className="order_asc" onClick={() => {changeOrder('website','asc')}}></span><span className="order_desc" onClick={() => {changeOrder('website','desc')}}></span></div>
                                    </div>
                                </Th>
                                <Th data-priority="1">
                                    <div className="tableCell">
                                        <span>Tags</span>
                                    </div>
                                </Th>
                            </Tr>
                            </Thead>
                            <Tbody>
                            {props.adtags.results.map((x, i) => {
                            return (
                                <Tr key={i} data-id={x.id}>
                                    <Th>{findWebsiteById(x.website)}</Th>
                                    <Td>
                                        <div className="tableCell tableCell-alignLeft">
                                            Copy Ad tag script to clipboard
                                            <a className="table_icon copy" onClick={(e) => {copyScript(x.id, x.website, e.target)}}>
                                                <div className="fadingPopup">
                                                    Copied to clipboard
                                                </div>
                                            </a>
                                        </div>
                                    </Td>
                                </Tr>
                            )
                            })}
                            </Tbody>
                        </Table>
                        </div>
                    </div>
                    {
                    //console.log("props.adtags.results.length = ", props.adtags.results.length , " ___props.adtags.count = ", props.adtags.count)
                    }
                    {props.adtags.results.length > 0 && props.adtags.results.length < props.adtags.count && (
                        [1].map(() => {
                            return (
                                <div key="1" className="pagination">
                                    <span className={props.adtags.previous ? 'active': ''} onClick={() => {paginationPrevNext(props.adtags.previous)}}></span>
                                    {[...Array(Math.ceil(props.adtags.count/props.adtags.results.length)).keys()].map((x, i) => {
                                        return (
                                            <a className={tableFilters.offset == i * tableFilters.limit || i === 0 && !tableFilters.offset ? 'active' : ''} key={i} onClick={()=> {addFilter({offset: i * tableFilters.limit})}}>{i + 1}</a>
                                        )
                                    })}
                                    <span className={props.adtags.next ? 'active': ''} onClick={() => {paginationPrevNext(props.adtags.next)}}></span>
                                </div>
                            )
                        })
                    )}
                    {props.adtags.error && (
                        <span className="error">{props.adtags.error}</span>
                    )}
                  </CardBody>  
                </Card>
            </Container>
        </div>
    )

}

// optionalArray: PropTypes.array,
// optionalBool: PropTypes.bool,
// optionalFunc: PropTypes.func,
// optionalNumber: PropTypes.number,
// optionalObject: PropTypes.object,
// optionalString: PropTypes.string,
// optionalSymbol: PropTypes.symbol,
// optionalAny: PropTypes.any

Adtags_madinad.propTypes = {
    getWebsites: PropTypes.func,
    getAdtags: PropTypes.func,
}

const mapStateToProps = state => {
    return { 
        websites: state.websites.results,
        publisher: state.User.publisher,
        adtags: state.adtags
    }
}

export default connect(mapStateToProps, {getWebsites, getAdtags})(withTranslation()(Adtags_madinad))