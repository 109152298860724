import React, { useEffect, useState } from "react"

import { Col } from "reactstrap"
import MiniCards from "./mini-card"

//Icons
import impression_icon from "../../../assets/images/madinad/impression_red.svg"
import cpm_icon from "../../../assets/images/madinad/cpm_red.svg"
import ctr_icon from "../../../assets/images/madinad/ctr_red.svg"
import viewability_icon from "../../../assets/images/madinad/viewability_red.svg"

import {GET_PUBLISHER_CAMPAIGNS_AVERAGE} from "../../../helpers/url_helper"
import {get} from "../../../helpers/api_helper"

const AverageCards = props => {
    
    const [avgData, setAvgData] = useState({
        ad_impressions: '-',
        avg_cpm: '-',
        avg_ctr: '-',
        avg_viewability: '-'
    })
    const [loading, setLoading] = useState('loading');

    useEffect(() => {
        setLoading('loading')
        let website = ''
        if (Array.isArray(props.selectedWebsites) && props.selectedWebsites.length == 1)
            website = props.selectedWebsites[0].id
        get(GET_PUBLISHER_CAMPAIGNS_AVERAGE, {
            website,
            dt_from: props.selectedDates.from,
            dt_to: props.selectedDates.to
        })
        .then(response => {
            setAvgData(response)
            setLoading('')
        })
        .catch(error => {
            console.log("GET_PUBLISHER_CAMPAIGNS_AVERAGE error = ", error)
            setLoading('')
        })
    }, [props.selectedDates, props.selectedWebsites])

    return (
        <React.Fragment>
            <Col xl={3} md={6} className={loading}>
                <MiniCards title={['Ad Impressions']} text={[avgData.ad_impressions]} icon={[impression_icon]}></MiniCards>
            </Col>
            <Col xl={3} md={6} className={loading}>
                <MiniCards title={['Average CPM']} text={[avgData.avg_cpm + '€']} icon={[cpm_icon]}></MiniCards>
            </Col>
            <Col xl={3} md={6} className={loading}>
                <MiniCards title={['Average CTR']} text={[(avgData.avg_ctr > 0 ? Math.round(100 * avgData.avg_ctr) : 0) + '%']} icon={[ctr_icon]}></MiniCards>
            </Col>
            <Col xl={3} md={6} className={loading}>
                {/* <MiniCards title={['Average Viewability']} text={[(avgData.avg_viewability > 0 ? Math.round(100 * avgData.avg_viewability) : 0) + '%']} icon={[viewability_icon]}></MiniCards> */}
                <MiniCards title={['Average Viewability']} text={['Feature coming soon']} icon={[viewability_icon]}></MiniCards>
            </Col>
        </React.Fragment>
    )
}

export default AverageCards