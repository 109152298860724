import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"
import ReactApexChart from "react-apexcharts"
import ApexCharts from 'apexcharts';

import {GET_PUBLISHER_REVENUE} from "../../../helpers/url_helper"
import {get} from "../../../helpers/api_helper"

import revenue_icon from "../../../assets/images/madinad/revenue_red.svg"

let difference_in_months = 0
let label_formatter_val = 0

// const last_3_years_data = []
// for (var i = 1; i < 4; i++) {
//     for (var j = 1; j < 13; j++) {
//         var random_revenue = Math.floor(Math.random() * 100) + 1;
//         // if (j !== 2)
//         var date =  (new Date().getFullYear() - 4) + i + '/' + j + '/01'
//         last_3_years_data.push([new Date(date), random_revenue])
//     }
// }
// const last_year_data = []
// for (var j = 1; j < 13; j++) {
//     var random_revenue = Math.floor(Math.random() * 100) + 1;
//     // if (j !== 2)
//     var date = (new Date().getFullYear() - 1) + '/' + j + '/01'
//     last_year_data.push([new Date(date), random_revenue])
// }
// const current_year_data = [];
// for (var i = 1; i <= new Date().getMonth() + 1; i++) {
//   var random_revenue = Math.floor(Math.random() * 100) + 1;
//   var date = new Date().getFullYear() + '/' + i + '/01'
//   current_year_data.push([new Date(date), random_revenue])
// }
// const last_6_months_data = [];
//   var random_revenue = Math.floor(Math.random() * 100) + 1;
//   last_6_months_data.push([new Date('2020/12/01'), random_revenue])
//   random_revenue = Math.floor(Math.random() * 100) + 1;
//   last_6_months_data.push([new Date('2021/01/01'), random_revenue])
//   random_revenue = Math.floor(Math.random() * 100) + 1;
//   last_6_months_data.push([new Date('2021/02/01'), random_revenue])
//   random_revenue = Math.floor(Math.random() * 100) + 1;
//   last_6_months_data.push([new Date('2021/03/01'), random_revenue])
//   random_revenue = Math.floor(Math.random() * 100) + 1;
//   last_6_months_data.push([new Date('2021/04/01'), random_revenue])
//   random_revenue = Math.floor(Math.random() * 100) + 1;
//   last_6_months_data.push([new Date('2021/05/01'), random_revenue])
// const last_3_months_data = [];
//   var random_revenue = Math.floor(Math.random() * 100) + 1;
//   last_3_months_data.push([new Date('2021/03/01'), random_revenue])
//   random_revenue = Math.floor(Math.random() * 100) + 1;
//   last_3_months_data.push([new Date('2021/04/01'), random_revenue])
//   random_revenue = Math.floor(Math.random() * 100) + 1;
//   last_3_months_data.push([new Date('2021/05/01'), random_revenue])
// const last_month_data = [];
// var random_revenue = Math.floor(Math.random() * 100) + 1;
// last_month_data.push([new Date('2021/05/01'), random_revenue])

function formatDate(date, format) {
  var year = date.getFullYear()
  var month = date.getMonth() + 1
  var dt = date.getDate()
  if (dt < 10) 
      dt = '0' + dt
  if (month < 10)
      month = '0' + month
  if (format === 'YYYY')
    return year
  if (format === 'MM')
    return month
  return year + '-' + month + '-' + dt
}

const RevenueChart = props => {

  const [loading, setLoading] = useState('loading');
  const [lineChartData, setLineChartData] = useState({
    total: 0,
    data: {
      series: [],
      options: {
        chart: {toolbar: "false",dropShadow: {enabled: !0,color: "#000",top: 18,left: 7,blur: 8,opacity: 0.2}},
        dataLabels: {enabled: !1},
        colors: ["#C24F7A"],
        stroke: {curve: "smooth",width: 3},
        xaxis: {
          type: 'datetime',
          // tickAmount: 1,
          // tickPlacement: 'between',
          // tickAmount: 2, // In type datetime this is applied only if you specify labels.formatter
          labels: {
            // formatter: function(val, timestamp) {
            //   label_formatter_val++
            //   // console.log("DATE DIF IN FORMATTER = ", difference_in_months)
            //   console.log("label formatted val = ", label_formatter_val)
            //   console.log(formatDate(new Date(timestamp)))
            //   // console.log('VAL = ', val)
            //   // console.log('TIMESTAMP = ', timestamp)
            //   return formatDate(new Date(timestamp))
            //   // return moment(timestamp).format("DD MMM YYYY");
            // },
            // format: 'MM yyyy',
            datetimeUTC: false
          }
        },
        tooltip: {
          x: {
            format: 'dd MMM yyyy'
          }
        },
      },
      // selection: 'one_year',
    }
  })

  useEffect(() => {
    setLoading('loading');
    let website_id = ''
    if (props.selectedWebsites.length === 1)
        website_id = props.selectedWebsites[0].id
      
    // console.log("website_id = ", website_id)
    // console.log("run_from = ", props.selectedDates.from)
    // console.log("run_to = ", props.selectedDates.to)

    get(GET_PUBLISHER_REVENUE, {
      dt_from: props.selectedDates.from, 
      dt_to: props.selectedDates.to,
      website: website_id
    })
    .then(response => {

        var combined_revenues = [];
        response.monthly_revenue.map((x) => {
          if (combined_revenues[x.month]) {
            combined_revenues[x.month] += x.month_revenue;
          }
          else {
            combined_revenues[x.month] = x.month_revenue;
          }
        });

        var formatted_data = [];
        for (var key of Object.keys(combined_revenues)) {
          formatted_data.push([new Date(key).getTime(), combined_revenues[key]])
        }
        formatted_data.sort((a,b) => a[0] - b[0]);

        //formatted_data.splice(1, 1); // TODO manos have to leave probably

        // Mock change data here based on chosen dates
        var start_date = new Date(props.selectedDates.from)
        var end_date = new Date(props.selectedDates.to)
        difference_in_months = (end_date.getFullYear()*12 + end_date.getMonth()) - (start_date.getFullYear()*12 + start_date.getMonth()) + 1;
        
        
        
        // var formatted_data = []
        // // last_3_years_data
        // // last_year_data
        // // current_year_data
        // // last_6_months_data
        // // last_3_months_data
        // // last_month_data
        // console.log("difference in months = ", difference_in_months)
        // if (difference_in_months === 36) {
        //   formatted_data = last_3_years_data
        // }
        // else if (difference_in_months === 12) {
        //   formatted_data = last_year_data
        // }
        // else if (difference_in_months === 6) {
        //   formatted_data = last_6_months_data
        // }
        // else if (difference_in_months === 3) {
        //   formatted_data = last_3_months_data
        // }
        // else if (difference_in_months === 1) {
        //   formatted_data = last_month_data
        // }
        // // Mock change data here based on chosen dates END
        // console.log(formatted_data)
        // formatted_data = formatted_data.map((x) => {
        //   return [x[0].getTime(), x[1]]
        // })
        // console.log(formatted_data)

        //data.options.xaxis.labels.format: 'YY'

        var chartData = {...lineChartData.data} // this was neccessary in campaigns-chart
        chartData.options = {...lineChartData.data.options}
        chartData.series = [{
          name: "Revenue",
          data: formatted_data
        }]

        // if (formatted_data.length > 0) {
        //   chartData.options.xaxis.min = formatted_data[0][0]
        //   chartData.options.xaxis.max = formatted_data[formatted_data.length - 1][0]
        // }

        // data.options.xaxis.tickAmount: 3
        // data.options.xaxis.labels.formatter: function(val, timestamp) {
        //   console.log('VAL = ', val)
        //   console.log('TIMESTAMP = ', timestamp)
        //   return formatDate(new Date(timestamp))
        //   // return moment(timestamp).format("DD MMM YYYY");
        // }

        // if (difference_in_months > 24) {
        //   // console.log("$$$$$$$$$tick ammount = ", difference_in_months/12)
        //   chartData.options.xaxis.tickAmount = difference_in_months/12
        //   chartData.options.xaxis.labels.formatter = function(val, timestamp) {
        //     return formatDate(new Date(timestamp), 'YYYY')
        //   }
        // }
        // else {
        //   // console.log("$$$$$$$$$tick ammount = ", difference_in_months)
        //   chartData.options.xaxis.tickAmount = difference_in_months
        //   chartData.options.xaxis.labels.formatter = function(val, timestamp) {
        //     return formatDate(new Date(timestamp), 'MM')
        //   }
        // }

        if (!response.total_revenue)
          response.total_revenue = 0

        setLineChartData({
          total: response.total_revenue,
          data: chartData
        })
        setLoading('');
    })
    .catch(error => {
        console.error("Campaign list error")
        console.error(error);
    })
  }, [props.selectedDates, props.selectedWebsites])

  return (
    <React.Fragment>
      <Col xl="8" className={loading}>
        <div className="cardwrapper">
          <Card className="canLoad">
            <div className="loader"></div>
            <CardBody>
              <h4 className="card-title icon-title mb-4">
                <div><img src={revenue_icon}></img></div>Revenue
              </h4>
              <Row>
                <Col lg="4">
                  <div className="text-muted">
                    <div>
                      <h4 className="mb-5">{lineChartData.total}€</h4>
                      <Link
                        to="/billing"
                        className="btn btn-primary waves-effect waves-light btn-sm"
                      >
                        View Details{" "}
                        <i className="mdi mdi-chevron-right ml-1"></i>
                      </Link>
                    </div>
                  </div>
                </Col>

                <Col lg="8">
                  <div id="line-chart" className="apex-charts" dir="ltr">
                    <ReactApexChart
                      key={lineChartData.data.options}
                      series={lineChartData.data.series}
                      options={lineChartData.data.options}
                      type="line"
                      height={230}
                    />
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
      </Col>
    </React.Fragment>
  )
}

export default RevenueChart