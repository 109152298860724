import PropTypes from 'prop-types'
import React from "react"
import { Card, CardBody, Media } from "reactstrap"

const MiniCards = props => {
  const { title, text, icon } = props
  let multiple = props.title.length > 1 ? 'cardwrapper-multiple' : ''
  return (
    <div className={"cardwrapper " + multiple}>
      {props.title.map((x, i) => {
        return (
          <div key={i} className="card minicard canLoad">
            <div className="loader"></div>
            <div className="card_body">
              <p className="font-weight-medium" style={{fontSize: "14px"}}>{title[i]}</p>
              <h4 className="mb-0">{text[i]}</h4>
            </div>
            <div className="card_img">
              <img src={icon[i]}></img>
            </div>
          </div>
        )
      })}
    </div>
  )
}

MiniCards.propTypes = {
  // iconClass: PropTypes.oneOfType([
  //   PropTypes.string,
  //   PropTypes.array
  // ]),
  // text: PropTypes.oneOfType([
  //   PropTypes.string,
  //   PropTypes.array
  // ]),
  // title: PropTypes.oneOfType([
  //   PropTypes.string,
  //   PropTypes.array
  // ]),
  iconClass: PropTypes.array,
  text: PropTypes.array,
  title: PropTypes.array
}

export default MiniCards
