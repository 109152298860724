import React, { useState, useEffect } from "react"
import PropTypes from 'prop-types'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
// Redux
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"

// users
import user1 from "../../../assets/images/users/avatar-1.jpg"

// Redux Store
import {
  getUserMe
} from "../../../store/actions"

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)

  const [username, setusername] = useState("Admin")
  const [userInitials, setUserInitials] = useState("")

  
  useEffect(() => {
    if (!props.user.username) {
      props.getUserMe()
    }
  }, []);

  useEffect(() => {
    let initials = "";
    if (props.user.username) {
      let v
      if (props.user.first_name && props.user.last_name)
        v = [props.user.first_name, props.user.last_name]
      else
        v = props.user.username.split(" ")

      if (v.length > 1)
        initials = v[0][0] + v[1][0]
      else if (v[0].length > 1)
        initials = v[0][0] + v[0][1]
      else
        initials = v[0][0]
      setUserInitials(initials)
    }
  }, [props.user]);

  // useEffect(() => {

  //   if (localStorage.getItem("authUser")) {
  //     if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
  //       const obj = JSON.parse(localStorage.getItem("authUser"))
  //       setusername(obj.displayName)
  //     } else if (
  //       process.env.REACT_APP_DEFAULTAUTH === "fake" ||
  //       process.env.REACT_APP_DEFAULTAUTH === "jwt"
  //     ) {
  //       const obj = JSON.parse(localStorage.getItem("authUser"))
  //       setusername(obj.username)
  //     }
  //   }
  // }, [props.success])

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
          tag="button"
        >
          {/* <img
            className="rounded-circle header-profile-user"
            src={user1}
            alt="Header Avatar"
          /> */}
          <div className="rounded-circle header-profile-user">
            {userInitials}
          </div>
          <span className="d-none d-xl-inline-block ml-2 mr-1" style={{textTransform: 'capitalize'}}>{ (props.user.first_name && props.user.last_name) ? props.user.first_name + ' ' + props.user.last_name : props.user.username}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block"/>
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem tag="a" href="/settings">
            {" "}
            <i className="bx bx-user font-size-16 align-middle mr-1"/>
            {props.t("Profile")}{" "}
          </DropdownItem>
          {/* <DropdownItem tag="a" href="/crypto-wallet">
            <i className="bx bx-wallet font-size-16 align-middle mr-1"/>
            {props.t("My Wallet")}
          </DropdownItem>
          <DropdownItem tag="a" href="#">
            <span className="badge badge-success float-right">11</span>
            <i className="mdi mdi-settings font-size-17 align-middle mr-1"/>
            {props.t("Settings")}
          </DropdownItem>
          <DropdownItem tag="a" href="auth-lock-screen">
            <i className="bx bx-lock-open font-size-16 align-middle mr-1"/>
            {props.t("Lock screen")}
          </DropdownItem> */}
          <div className="dropdown-divider"/>
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger"/>
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
  getUserMe: PropTypes.func,
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success, user: state.User }
}

export default withRouter(
  connect(mapStatetoProps, {getUserMe})(withTranslation()(ProfileMenu))
)
