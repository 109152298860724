import { call, put, takeEvery, takeLatest } from "redux-saga/effects"
import { GET_WEBSITES, GET_WEBSITE } from "./actionTypes"
import { getWebsitesSuccess, getWebsitesFail, getWebsiteSuccess, getWebsiteFail } from "./actions"

import { getWebsites, getWebsite } from "../../../helpers/backend_helper"

function* getWebsitesInfo() {
    try {
        const response = yield call(getWebsites)
        if (response)
            yield put(getWebsitesSuccess(response))
        else
            yield put(getWebsitesFail('could not fetch campaigns'))
    }
    catch (error) {
        yield put(getWebsitesFail("Bad response from server"))
    }
}

function* getWebsiteInfo(id) {
    try {
        const response = yield call(getWebsite(id))
        if (response)
            yield put(getWebsiteSuccess(response))
        else
            yield put(getWebsiteFail('could not fetch campaign'))
    } 
    catch(error) {
        yield put(getWebsiteFail("Bad response from server"))
    }
}

function* websiteSagas() {
    yield takeEvery(GET_WEBSITES, getWebsitesInfo)
    yield takeEvery(GET_WEBSITE, getWebsiteInfo)
}

export default websiteSagas