import { 
    GET_USER,
    GET_USER_SUCCESS,
    GET_USER_FAIL,
    GET_USER_ME,
    GET_USER_ME_SUCCESS,
    GET_USER_ME_FAIL,
    PUT_USER,
    PUT_USER_SUCCESS,
    PUT_USER_FAIL,
    PATCH_USER,
    PATCH_USER_SUCCESS,
    PATCH_USER_FAIL,
    PATCH_USER_SET_PASSWORD,
    PATCH_USER_SET_PASSWORD_SUCCESS,
    PATCH_USER_SET_PASSWORD_FAIL
 } from "./actionTypes"

const initialState = {
    id: "",
    username: "",
    email: "",
    first_name: "",
    last_name: "",
    is_active: "",
    date_joined: "",
    user_type: "",
    publisher: "",
    user_list: [],
    error: "",
    success: "",
}

const user = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER:
      state = { ...state }
      break
    case GET_USER_SUCCESS:
      state = { ...state, user_list: {...action.payload} }
      break
    case GET_USER_FAIL:
      state = { ...state, error: action.payload }
      break
    case GET_USER_ME:
      state = { ...state }
      break
    case GET_USER_ME_SUCCESS:
      state = { ...state, ...action.payload }
      break
    case GET_USER_ME_FAIL:
      state = { ...state, error: action.payload }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default user