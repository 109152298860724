import {
    GET_USER,
    GET_USER_SUCCESS,
    GET_USER_FAIL,
    GET_USER_ME,
    GET_USER_ME_SUCCESS,
    GET_USER_ME_FAIL,
    PUT_USER,
    PUT_USER_SUCCESS,
    PUT_USER_FAIL,
    PATCH_USER,
    PATCH_USER_SUCCESS,
    PATCH_USER_FAIL,
    PATCH_USER_SET_PASSWORD,
    PATCH_USER_SET_PASSWORD_SUCCESS,
    PATCH_USER_SET_PASSWORD_FAIL
} from "./actionTypes"

export const getUser = () => ({
    type: GET_USER,
})

export const getUserSuccess = data => ({
    type: GET_USER_SUCCESS,
    payload: data,
})

export const getUserFail = error => ({
    type: GET_USER_FAIL,
    payload: error,
})

export const getUserMe = () => ({
    type: GET_USER_ME,
})

export const getUserMeSuccess = data => ({
    type: GET_USER_ME_SUCCESS,
    payload: data,
})

export const getUserMeFail = error => ({
    type: GET_USER_ME_FAIL,
    payload: error,
})

export const putUser = (id, publisher) => ({
    type: PUT_USER,
})

export const putUserSuccess = () => ({
    type: PUT_USER_SUCCESS,
    payload: messages,
})

export const putUserFail = error => ({
    type: PUT_USER_FAIL,
    payload: error,
})

export const patchUser = (id, publisher) => ({
    type: PATCH_USER,
})

export const patchUserSuccess = () => ({
    type: PATCH_USER_SUCCESS,
    payload: messages,
})

export const patchUserFail = error => ({
    type: PATCH_USER_FAIL,
    payload: error,
})

export const patchUserSetPassword = (id, old_password, new_password) => ({
    type: PATCH_USER_SET_PASSWORD,
})

export const patchUserSetPasswordSuccess = () => ({
    type: PATCH_USER_SET_PASSWORD_SUCCESS,
    payload: messages,
})

export const patchUserSetPasswordFail = error => ({
    type: PATCH_USER_SET_PASSWORD_FAIL,
    payload: error,
})