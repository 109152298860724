/* EVENTS */
export const GET_USER = "GET_USER"
export const GET_USER_SUCCESS = "GET_USER_SUCCESS"
export const GET_USER_FAIL = "GET_USER_FAIL"

export const GET_USER_ME = "GET_USER_ME"
export const GET_USER_ME_SUCCESS = "GET_USER_ME_SUCCESS"
export const GET_USER_ME_FAIL = "GET_USER_ME_FAIL"

export const PUT_USER = "PUT_USER"
export const PUT_USER_SUCCESS = "PUT_USER_SUCCESS"
export const PUT_USER_FAIL = "PUT_USER_FAIL"

export const PATCH_USER = "PATCH_USER"
export const PATCH_USER_SUCCESS = "PATCH_USER_SUCCESS"
export const PATCH_USER_FAIL = "PATCH_USER_FAIL"

export const PATCH_USER_SET_PASSWORD = "PATCH_USER_SET_PASSWORD"
export const PATCH_USER_SET_PASSWORD_SUCCESS = "PATCH_USER_SET_PASSWORD_SUCCESS"
export const PATCH_USER_SET_PASSWORD_FAIL = "PATCH_USER_SET_PASSWORD_FAIL"