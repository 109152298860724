import PropTypes from 'prop-types'
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"

import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"

import {
    Card,
    CardBody,
    CardTitle,
    Col,
    Container,
    Media,
    Row,
    FormGroup
} from "reactstrap"

import Select from "react-select"

// Redux
import { connect } from "react-redux"

// Redux Store
import {
    getWebsites,
    getCampaigns
} from "../../../store/actions"

//Import Components
// import ActiveCampaigns from "./active-campaigns.js"
// import DataCharts from "./data-charts.js"

const Campaigns_madinad = props => {

    const [loading, setLoading] = useState('loading');
    const [options_websites, setOptions_websites] = useState([{ label: "Website", value: "" }])
    const [tableFilters, setTableFilters] = useState({limit: 25});
    // const [tableFilters, setTableFilters] = useState({});
    const [columnOrder, setColumnOrder] = useState({website: '', status: '', run_from: '', run_to: ''});

    useEffect(() => {
        if (!props.websites.length)
            props.getWebsites()
    }, [])

    useEffect(() => {
        setLoading('loading')
        props.getCampaigns(tableFilters)
    }, [tableFilters])

    useEffect(()=> {
        setLoading('')
    }, [props.campaigns.results])

    function changeOrder(key, order) {

        if (columnOrder[key] === "asc") {
            if (order === "asc")
                columnOrder[key] = ''
            else
                columnOrder[key] = 'desc'
        }
        else if (columnOrder[key] === "desc") {
            if (order === "desc")
                columnOrder[key] = ''
            else
                columnOrder[key] = 'asc'
        }
        else {
            if (order === "desc")
                columnOrder[key] = 'desc'
            else
                columnOrder[key] = 'asc'
        }
        setColumnOrder({...columnOrder})
        var ordering = ''
        var hasOne = false;
        Object.entries(columnOrder).map(([key, value]) => {
            if (value === 'asc') {
                ordering += (hasOne ? ',' : '') + key
                hasOne = true
            }
            else if (value === 'desc') {
                ordering += (hasOne ? ',' : '') + '-' + key
                hasOne = true
            }
                
        })
        addFilter({ordering})
    }

    let options_dates = [
        {label: "Start Date", value: ""},
        {label: "Before 1 month", value: "before_1_month"},
        {label: "Before 2 months", value: "before_2_months"},
        {label: "Before 3 months", value: "before_3_months"},
        {label: "Before 6 months", value: "before_6_months"},
        {label: "Before 1 Year", value: "before_1_year"},
        {label: "Before 2 Years", value: "before_2_years"},
    ]

    let options_dates_end = [
        {label: "End Date", value: ""},
        {label: "Before 1 month", value: "before_1_month"},
        {label: "Before 2 months", value: "before_2_months"},
        {label: "Before 3 months", value: "before_3_months"},
        {label: "Before 6 months", value: "before_6_months"},
        {label: "Before 1 Year", value: "before_1_year"},
        {label: "Before 2 Years", value: "before_2_years"},
    ]

    let options_status = [
        {label: "Status", value: ''},
        {label: "Active", value: 'active'},
        {label: "Completed", value: 'compelted'},
        {label: "Pending", value: 'pending'},
    ]

    useEffect(() => {
        if (props.websites.length > 0) {
            let all_websites = [ { label: "Website", value: "" } ]
            props.websites.forEach((x) => {
                all_websites.push({label: x.name, value: x.id});
            });
            setOptions_websites(all_websites);
        }
    }, [props.websites])

    function handleSelectedDate(option, key) {
        var filter = {}
        filter[key] = ""
        var today = new Date()
        if (option.value === "before_1_month") {
            if (key === "start_date")
                filter[key] = new Date(today.getFullYear(), today.getMonth()-1, 1);
            else
                filter[key] = new Date(today.getFullYear(), today.getMonth(), 0);
        }
        else if (option.value === "before_2_months") {
            if (key === "start_date")
                filter[key] = new Date(today.getFullYear(), today.getMonth()-2, 1);
            else
                filter[key] = new Date(today.getFullYear(), today.getMonth()-1, 0);
        }
        else if (option.value === "before_3_months") {
            if (key === "start_date")
                filter[key] = new Date(today.getFullYear(), today.getMonth()-3, 1);
            else
                filter[key] = new Date(today.getFullYear(), today.getMonth()-2, 0);
        }
        else if (option.value === "before_6_months") {
            if (key === "start_date")
                filter[key] = new Date(today.getFullYear(), today.getMonth()-6, 1);
            else
                filter[key] = new Date(today.getFullYear(), today.getMonth()-5, 0);
        }
        else if (option.value === "before_1_year") {
            if (key === "start_date")
                filter[key] = new Date(today.getFullYear()-1, 0, 1);
            else
                filter[key] = new Date(today.getFullYear(), 11, 31);
        }
        else if (option.value === "before_2_years") {
            if (key === "start_date")
                filter[key] = new Date(today.getFullYear()-2, 0, 1);
            else
                filter[key] = new Date(today.getFullYear()-1, 11, 31);
        }
        if (option.value)
            filter[key] = formatDate(filter[key])
        
        addFilter(filter)
    }

    function paginationPrevNext (url) {
        var rgx = /offset=[\d]+/
        var offset = rgx.exec(url)
        if (offset)
            offset = offset[0].split('=')[1]
        else
            offset = 0
        
        addFilter({offset})
    }

    function formatDate(date, format) {
        if (!(date instanceof Date))
            date = new Date(date)
        var year = date.getFullYear()
        var month = date.getMonth() + 1
        var day = date.getDate()
        if (day < 10) 
            day = '0' + day
        if (month < 10)
            month = '0' + month

        if (format === "DD/MM/YYYY")
            return day + '/' + month + '/' + year
        else
            return year + '-' + month + '-' + day
    }
    
    function addFilter(filter) {
        setTableFilters({...tableFilters, ...filter})
    }

    var searchTimeout
    function onSearchChange(val) {
        clearTimeout(searchTimeout);
        searchTimeout  =  setTimeout(function() {
            addFilter({name: val})
        }, 300);
    }

    function goToLink(url) {
        window.location.href = url
    }

    return (
        <div className="page-content">
            <Container fluid>
                <h2 className="pageTitle">Campaigns</h2>
                <Card className={loading}>
                    <CardBody className="canLoad">
                        <div className="loader"></div>
                    <div className="table_filters d-flex justify-content-between flex-wrap">
                        <div className="table_filters_search">
                            <input onChange={(e) => {onSearchChange(e.target.value)}} placeholder="Search Campaign Name"></input>
                            <span></span>
                        </div>
                        <div className="d-flex flex-wrap table_filters_selects">
                            <FormGroup className={"select2-container " + (!('website_id' in tableFilters) || tableFilters.website_id === '' ? '' : 'active')} style={{width: `${7 * options_websites[0].label.length + 40}px`}}>
                                <Select
                                defaultValue={options_websites[0]}
                                onChange={(option)=>{addFilter({website_id: option.value})}}
                                options={options_websites}
                                classNamePrefix="select2-selection"
                                isSearchable={false}
                                />
                            </FormGroup>
                            <FormGroup className={"select2-container " + (!('campaign_status' in tableFilters) || tableFilters.campaign_status === '' ? '' : 'active')} style={{width: `${7 * options_status[0].label.length + 40}px`}}>
                                <Select
                                defaultValue={options_status[0]}
                                onChange={(option)=>{addFilter({campaign_status: option.value})}}
                                options={options_status}
                                classNamePrefix="select2-selection"
                                isSearchable={false}
                                />
                            </FormGroup>
                            <FormGroup className={"select2-container " + (!('start_date' in tableFilters) || tableFilters.start_date === '' ? '' : 'active')} style={{width: `${7 * options_dates[0].label.length + 40}px`}}>
                                <Select
                                defaultValue={options_dates[0]}
                                onChange={(option) => {handleSelectedDate(option, 'start_date')}}
                                options={options_dates}
                                classNamePrefix="select2-selection"
                                isSearchable={false}
                                />
                            </FormGroup>
                            <FormGroup className={"select2-container " + (!('end_date' in tableFilters) || tableFilters.end_date === '' ? '' : 'active')} style={{width: `${7 * options_dates_end[0].label.length + 40}px`}}>
                                <Select
                                defaultValue={options_dates_end[0]}
                                onChange={(option) => {handleSelectedDate(option, 'end_date')}}
                                options={options_dates_end}
                                classNamePrefix="select2-selection"
                                isSearchable={false}
                                />
                            </FormGroup>
                        </div>
                    </div>
                    <div className="table-rep-plugin">
                        <div
                        className="table-responsive mb-0"
                        data-pattern="priority-columns"
                        >
                        <Table
                            id="tech-companies-1"
                            className="table table-striped table-bordered campaignsTable"
                        >
                            <Thead>
                            <Tr>
                                <Th><div className={"tableCell " + columnOrder.name}>
                                        <span onClick={() => {changeOrder('name')}}>Campaign</span>
                                        <div className="order"><span className="order_asc" onClick={() => {changeOrder('name','asc')}}></span><span className="order_desc" onClick={() => {changeOrder('name','desc')}}></span></div>
                                    </div>
                                </Th>
                                <Th data-priority="1"><div className={"tableCell " + columnOrder.campaign_status}>
                                    <span onClick={() => {changeOrder('campaign_status')}}>Status</span>
                                    <div className="order"><span className="order_asc" onClick={() => {changeOrder('campaign_status','asc')}}></span><span className="order_desc" onClick={() => {changeOrder('campaign_status','desc')}}></span></div></div>
                                </Th>
                                <Th data-priority="3"><div className={"tableCell " + columnOrder.run_from}>
                                    <span onClick={() => {changeOrder('run_from')}}>Start Date</span>
                                    <div className="order"><span className="order_asc" onClick={() => {changeOrder('run_from', 'asc')}}></span><span className="order_desc" onClick={() => {changeOrder('run_from','desc')}}></span></div></div>
                                </Th>
                                <Th data-priority="1"><div className={"tableCell " + columnOrder.run_to}>
                                    <span onClick={() => {changeOrder('run_to')}}>End Date</span>
                                    <div className="order"><span className="order_asc" onClick={() => {changeOrder('run_to','asc')}}></span><span className="order_desc" onClick={() => {changeOrder('run_to','desc')}}></span></div></div>
                                </Th>
                                <Th data-priority="3">
                                    <div className="tableCell">
                                        <span>Tags</span>
                                    </div>
                                </Th>
                            </Tr>
                            </Thead>
                            <Tbody>
                            {props.campaigns.results.map((x, i) => {
                            return (
                                <Tr key={i} data-id={x.id} onClick={() => {goToLink('/reports/' + x.id)}} target="_blank">
                                    <Th><a href={"/reports/" + x.id}>{x.name}</a></Th>
                                    <Td>{x.campaign_status}</Td>
                                    <Td>{formatDate(x.run_from, 'DD/MM/YYYY')}</Td>
                                    <Td>{formatDate(x.run_to, 'DD/MM/YYYY')}</Td>
                                    <Td><div className="tableCell">Ad tags<a className="table_icon linkarrow"  href={"/adtags/" + x.id}></a></div></Td>
                                </Tr>
                            )
                            })}
                            </Tbody>
                        </Table>
                        </div>
                    </div>
                    {props.campaigns.results.length > 0 && props.campaigns.results.length < props.campaigns.count && (
                        [1].map(() => {
                            return (
                                <div key="1" className="pagination">
                                    <span className={props.campaigns.previous ? 'active': ''} onClick={() => {paginationPrevNext(props.campaigns.previous)}}></span>
                                    {[...Array(Math.ceil(props.campaigns.count/props.campaigns.results.length)).keys()].map((x, i) => {
                                        return (
                                            <a className={tableFilters.offset == i * tableFilters.limit || i === 0 && !tableFilters.offset ? 'active' : ''} key={i} onClick={()=> {addFilter({offset: i * tableFilters.limit})}}>{i + 1}</a>
                                        )
                                    })}
                                    <span className={props.campaigns.next ? 'active': ''} onClick={() => {paginationPrevNext(props.campaigns.next)}}></span>
                                </div>
                            )
                        })
                    )}
                    {props.campaigns.error && (
                        <span className="error">{props.campaigns.error}</span>
                    )}
                  </CardBody>  
                </Card>
            </Container>
        </div>
    )

}

// optionalArray: PropTypes.array,
// optionalBool: PropTypes.bool,
// optionalFunc: PropTypes.func,
// optionalNumber: PropTypes.number,
// optionalObject: PropTypes.object,
// optionalString: PropTypes.string,
// optionalSymbol: PropTypes.symbol,
// optionalAny: PropTypes.any

Campaigns_madinad.propTypes = {
    getWebsites: PropTypes.func,
    getCampaigns: PropTypes.func,
}

const mapStateToProps = state => {
    return { 
        websites: state.websites.results,
        publisher: state.User.publisher,
        campaigns: state.campaigns
    }
}

export default connect(mapStateToProps, {getWebsites, getCampaigns})(withTranslation()(Campaigns_madinad))