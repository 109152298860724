import PropTypes from 'prop-types'
import React, { useEffect, useState, useRef } from "react"

import {
    Card,
    CardBody,
    Container,
    Alert
} from "reactstrap"

import SweetAlert from "react-bootstrap-sweetalert"

// availity-reactstrap-validation
import { AvForm, AvField, AvGroup, AvInput } from "availity-reactstrap-validation"

// Redux
import { connect } from "react-redux"

// Redux Store
import {
    getUserMe
} from "../../../store/actions"

import {PATCH_USER_SET_PASSWORD} from "../../../helpers/url_helper"
import {patch} from "../../../helpers/api_helper"

const UserSettings_madinad = props => {

    const [showSuccessMsg, setShowSuccessMsg] = useState();
    const [formError, setFormError] = useState('');
    const newPasswordRef = useRef()
    var myForm

    useEffect(() => {
        if (!props.user.username)
            props.getUserMe()
    }, [])

    const handleValidSubmit = (event, values) => {
        patch(PATCH_USER_SET_PASSWORD, {
            old_password: values.old_password,
            new_password: values.new_password
        })
        .then(response => {
            setFormError('');
            setShowSuccessMsg(true)
        })
        .catch(error => {
            let errorMsg = 'Wrong password or new password is not valid';
            if (error.response && error.response.data) {
                errorMsg = '';
                Object.keys(error.response.data).forEach(function(key) {
                    errorMsg += error.response.data[key] + '\n';
                });
            }
            setFormError(errorMsg);
        })
    }

    function validateConfirmNewPassword (value, ctx, input, cb) {
        if (value !== ctx.new_password)
            return 'Passwords do not match'
        return true
    }

    var validateConfirmPassword = () => {
        myForm.validateInput('new_password_confirm')
    }

    return (
        <div className="page-content">
            <Container fluid>
                {showSuccessMsg ? (
                    <SweetAlert
                      title="Αλλαγή κωδικού"
                      success
                      confirmBtnBsStyle="success"
                      confirmBtnText="Επιστροφή στο dashboard"
                      onConfirm={() => {
                        window.location.href = "/dashboard"
                      }}
                    >
                    Ο κωδικός σας άλλαξε με επιτυχία
                    </SweetAlert>
                  ) : null}
                <h2 className="pageTitle">User Settings</h2>
                <h5 className>{ (props.user.first_name && props.user.last_name) ? props.user.first_name + ' ' + props.user.last_name : props.user.username}</h5>
                <div className="text-muted subtitle">
                    {(() => {
                        if (props.user.email)
                            return <div>{props.user.email}</div>
                    })()}
                    <div>
                        {(() => {
                            if (props.user.publisher)
                                return <span>{props.user.publisher}</span>
                        })()}
                        {(() => {
                            if (props.user.user_type)
                                return <span>{props.user.user_type}</span>
                        })()}
                    </div>
                </div>
                <Card>
                    <CardBody>
                        <h6>Change Password</h6>
                        <div className="inputInfo">
                            <div>Your password must be at least 8 characters</div>
                            <div>Your password cannot contain parts of your username</div>
                            <div>Your password cannot consist of just numbers</div>
                            <div>Your password cannot be a common password (ex. 1234)</div>
                        </div>
                        <AvForm
                        ref={c => (myForm = c)}
                        className="form-madinad"
                        onValidSubmit={(e, v) => {
                            handleValidSubmit(e, v)
                        }}
                        >

                            {formError ? (
                                <Alert color="danger">{formError}</Alert>
                            ) : null}

                            <AvField
                            name="old_password"
                            label="Old Password *"
                            value=""
                            className="form-control"
                            type="password"
                            validate={{
                                required: {value: true, errorMessage: 'Please enter old password'},
                                minLength: {value: 8, errorMessage: 'Your password must be at least 8 characters'}
                            }} />
                            <div className="form-group_wrapper d-flex flex-wrap justify-content-between">
                                <AvField
                                ref={newPasswordRef}
                                name="new_password"
                                label="New Password *"
                                value=""
                                className="form-control"
                                type="password"
                                onChange={validateConfirmPassword}
                                validate={{
                                    required: {value: true, errorMessage: 'Please enter new password'},
                                    minLength: {value: 8, errorMessage: 'Your password must be at least 8 characters'}
                                }} />
                                <AvField
                                name="new_password_confirm"
                                label="Confirm New Password *"
                                value=""
                                className="form-control"
                                type="password"
                                validate={{
                                    required: {value: true, errorMessage: 'Please reenter new password'},
                                    minLength: {value: 8, errorMessage: 'Your password must be at least 8 characters'},
                                    custom: validateConfirmNewPassword
                                }} />
                            </div>
                            <div className="d-flex justify-content-end justify-content-sm-start mb-1 mb-sm-0">
                                <button
                                className="btn btn-red btn-block waves-effect waves-light"
                                type="submit"
                                >
                                Save changes
                                </button>
                            </div>
                        </AvForm>
                    </CardBody>
                </Card>
            </Container>
        </div>
    )

}

UserSettings_madinad.propTypes = {
    getUserMe: PropTypes.func,
}

const mapStateToProps = state => {
    return { 
        websites: state.websites.results,
        user: state.User,
    }
}

export default connect(mapStateToProps, {getUserMe})((UserSettings_madinad))