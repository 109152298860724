/* EVENTS */
export const GET_CAMPAIGNS = "GET_CAMPAIGNS"
export const GET_CAMPAIGNS_SUCCESS = "GET_CAMPAIGNS_SUCCESS"
export const GET_CAMPAIGNS_FAIL = "GET_CAMPAIGNS_FAIL"

export const GET_CAMPAIGN = "GET_CAMPAIGN"
export const GET_CAMPAIGN_SUCCESS = "GET_CAMPAIGN_SUCCESS"
export const GET_CAMPAIGN_FAIL = "GET_CAMPAIGN_FAIL"

export const GET_CAMPAIGN_ANALYTICS = "GET_CAMPAIGN_ANALYTICS"
export const GET_CAMPAIGN_ANALYTICS_SUCCESS = "GET_CAMPAIGN_ANALYTICS_SUCCESS"
export const GET_CAMPAIGN_ANALYTICS_FAIL = "GET_CAMPAIGN_ANALYTICS_FAIL"