import { post, del, get, put, patch } from "./api_helper"
import * as url from "./url_helper"

// TOKEN
const postLogin = data => post(url.POST_TOKEN_CREATE, data)

// USERS
const getUserInfo = () => get(url.GET_USER_ME)

// CAMPAIGNS
const getCampaigns = params => get(url.GET_CAMPAIGNS, params)
const getCampaign = params => get(url.GET_CAMPAIGN, params)
const getCampaignAnalytics = params => get(url.GET_CAMPAIGN_ANALYTICS, params)

// WEBSITES
const getWebsites = () => get(url.GET_WEBSITES)
const getWebsite = params => get(url.GET_WEBSITE, params)

// BILLING
const getBilling = params => get(url.GET_BILLING, params)
const putBilling = (data, params) => put(url.PUT_BILLING, data, params)
const patchBilling = (data, params) => patch(url.PATCH_BILLING, data, params)

// ADTAGS
const getAdtags = params => get(url.GET_ADTAGS, params)

export { 
    postLogin, 
    getUserInfo,
    getCampaigns,
    getCampaign,
    getCampaignAnalytics,
    getWebsites,
    getWebsite,
    getBilling,
    putBilling,
    patchBilling,
    getAdtags
}
