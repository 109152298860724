import {
  GET_BILLING,
  GET_BILLING_SUCCESS,
  GET_BILLING_FAIL,
  PUT_BILLING,
  PUT_BILLING_SUCCESS,
  PUT_BILLING_FAIL,
  PATCH_BILLING,
  PATCH_BILLING_SUCCESS,
  PATCH_BILLING_FAIL
} from "./actionTypes"

const initialState = {
    count: 0,
    next: null,
    previous: null,
    results: [],
    error: "",
    error_put: "",
    success_put: "",
    error_patch: "",
    success_patch: ""
}

const billings = (state = initialState, action) => {
    switch (action.type) {
      case GET_BILLING:
        state = { ...state}
        break
      case GET_BILLING_SUCCESS:
        state = { ...state, ...action.payload, error: "" }
        break
      case GET_BILLING_FAIL:
        state = { ...state, error: action.payload }
        break
      case PUT_BILLING:
        state = { ...state}
        break
      case PUT_BILLING_SUCCESS:
        state = { ...state, success_put: action.payload, error_put: "" }
        break
      case PUT_BILLING_FAIL:
        state = { ...state, error_put: action.payload }
        break
      case PATCH_BILLING:
        state = { ...state}
        break
      case PATCH_BILLING_SUCCESS:
        state = { ...state, success_patch: action.payload, error_patch: "" }
        break
      case PATCH_BILLING_FAIL:
        state = { ...state, error_patch: action.payload }
        break
      default:
        state = { ...state }
        break
    }
    return state
  }
  
  export default billings