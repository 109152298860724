/************************MADINAD************************/

// TOKEN
export const POST_TOKEN_CREATE = "/token/"
// { "username": "<string>", "password": "<string>" }
export const POST_TOKEN_REFRESH_CREATE = "/token/refresh/"
// { "refresh": "<string>" }

// USERS
export const GET_USER = "/users/"
export const GET_USER_ME = "/users/me/"
export const PUT_USER = "/users/:id/"
/* { "publisher": "<string>" }*/
export const PATCH_USER = "/users/:id/"
/* { "publisher": "<string>" }*/
export const PATCH_USER_SET_PASSWORD = "/users/set_password/"
// { "old_password": "<string>", "new_password": "<string>" }

// CAMPAIGNS
export const GET_CAMPAIGNS = "/campaigns/"
// ?id=&name=&brand=&advertiser=&start_date=&end_date=&campaign_status=&limit=&offset=
export const GET_CAMPAIGN = "/campaigns/:id/"
export const GET_CAMPAIGN_ANALYTICS = "/campaigns/:id/analytics/"
export const GET_CAMPAIGN_PUBLISHER_ANALYTICS = "/campaigns/:id/publisher_analytics/"

// WEBSITES
export const GET_WEBSITES = "/websites/"
// ?name=<string>&url=<string>&limit=<integer>&offset=<integer>
export const GET_WEBSITE = "/websites/:id/"

// BILLING LIST
export const GET_BILLING = "/billing/"
export const PUT_BILLING = "/billing/:id/"
export const PATCH_BILLING = "/billing/:id/"
// ?campaign_name=<string>&payment_sent_to_publisher=<string>&advertisement_order_sent=<string>&limit=<integer>&offset=<integer>

// PUBLISHER
export const GET_PUBLISHER_MESSAGES = "/publisher-messages/"
// ?limit=<integer>&offset=<integer>
export const GET_PUBLISHER_REVENUE = "/publishers/revenue/"
// ?website=&dt_from=&dt_to=
export const GET_PUBLISHER_OVERVIEW = "/publishers/overview/"
export const GET_PUBLISHER_CAMPAIGNS_AVERAGE = "/publishers/campaigns/"
// ?website=&dt_from=&dt_to=

// ADFORMATS
/* adformat fields
{
    "ad_format_name": "<string>",
    "short_code": "<string>",
    "background_color": "<string>",
    "background_opacity": "<string>",
    "position_top": "<integer>",
    "position_bottom": "<integer>",
    "position_left": "<integer>",
    "position_right": "<integer>",
    "format_html": "<string>",
    "custom_css": "<string>",
    "custom_js": "<string>",
    "is_inarticle": "<boolean>",
    "has_index": "<boolean>",
    "close_btn_top": "<string>",
    "close_btn_buttom": "<string>",
    "close_btn_left": "<string>",
    "close_btn_right": "<string>",
    "auto_close_timeout": "<integer>",
    "display_after": "<integer>",
    "has_vimeo_support": "<boolean>",
    "description": "<string>"
}
*/
export const GET_ADFORMATS = "/adformats/"
// ?description=&ad_format_name=&short_code=
export const POST_ADFORMAT = "/adformats/"
// adformat fields
export const GET_ADFORMAT = "/adformats/:id/"
export const PUT_ADFORMAT = "/adformats/:id/"
// adformat fields
export const PATCH_ADFORMAT = "/adformats/:id/"
// adformat fields
export const DEL_ADFORMAT = "/adformats/:id/"

// ADPLACEMENTS
export const GET_ADPLACEMENETS = "/adplacements/"
// ?campaign_name=&website_name=&website_url=&accelerated_delivery=&enabled=&billing_month=&billing_year=&limit=&offset=
export const GET_ADPLACEMENET = "/adplacements/:id/"
export const PUT_ADPLACEMENET = "/adplacements/:id/"
// { "enabled": "<boolean>" }
export const PATCH_ADPLACEMENET = "/adplacements/:id/"
// { "enabled": "<boolean>" }

// ADTAGS
export const GET_ADTAGS = "/adtags/"
// ?campaign=<string>&limit=<integer>&offset=<integer>
export const GET_ADTAG = "/adtags/:id/"


// BLOCKED-URLS
export const GET_BLOCKED_URLS = "/blocked-urls/"
// ?limit=<integer>&offset=<integer>
export const POST_BLOCKED_URL = "/blocked-urls/"
// { "url": "https://www.test.com/" }
export const GET_BLOCKED_URL = "/blocked-urls/:id/"
export const PUT_BLOCKED_URL = "/blocked-urls/:id/"
// { "url": "<uri>" }
export const PATCH_BLOCKED_URL = "/blocked-urls/:id/"
// { "url": "<uri>" }
export const DEL_BLOCKED_URL = "/blocked-urls/:id/"

// CREATIVES
export const GET_CREATIVE = "/creatives/:id/"
export const GET_CREATIVE_ANALYTICS = "/creatives/:id/analytics/"

/************************THEME************************/

//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register"

//LOGIN
export const POST_FAKE_LOGIN = "/post-fake-login"
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login"
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd"
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd"
export const SOCIAL_LOGIN = "/social-login"

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile"
export const POST_EDIT_PROFILE = "/post-fake-profile"

//PRODUCTS
export const GET_PRODUCTS = "/products"
export const GET_PRODUCTS_DETAIL = "/product"

//CALENDER
export const GET_EVENTS = "/events"
export const ADD_NEW_EVENT = "/add/event"
export const UPDATE_EVENT = "/update/event"
export const DELETE_EVENT = "/delete/event"
export const GET_CATEGORIES = "/categories"

//CHATS
export const GET_CHATS = "/chats"
export const GET_GROUPS = "/groups"
export const GET_CONTACTS = "/contacts"
export const GET_MESSAGES = "/messages"
export const ADD_MESSAGE = "/add/messages"

//ORDERS
export const GET_ORDERS = "/orders"

//CART DATA
export const GET_CART_DATA = "/cart"

//CUSTOMERS
export const GET_CUSTOMERS = "/customers"

//SHOPS
export const GET_SHOPS = "/shops"

//CRYPTO
export const GET_WALLET = "/wallet"
export const GET_CRYPTO_ORDERS = "/crypto/orders"

//INVOICES
export const GET_INVOICES = "/invoices"
export const GET_INVOICE_DETAIL = "/invoice"

//PROJECTS
export const GET_PROJECTS = "/projects"
export const GET_PROJECT_DETAIL = "/project"

//TASKS
export const GET_TASKS = "/tasks"

//CONTACTS
export const GET_USERS = "/users"
export const GET_USER_PROFILE = "/user"