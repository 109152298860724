import React from "react"
import PropTypes from "prop-types"
import { Route, Redirect } from "react-router-dom"
import jwt_decode from "jwt-decode";

// Check if token is expired
function tokenIsValid(token) {
  if (!token)
    return false;
  let decodedToken = jwt_decode(token);
  let currentDate = new Date();
  // JWT exp is in seconds
  if (decodedToken.exp * 1000 < currentDate.getTime()) // token expired
    return false;
  return true;
}

// TODO add remember me

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      if (isAuthProtected && (!localStorage.getItem("refreshToken") || !tokenIsValid(localStorage.getItem("refreshToken")))) {
        return (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }

      return (
        <Layout>
          <Component {...props} />
        </Layout>
      )
    }}
  />
)

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
}

export default Authmiddleware
