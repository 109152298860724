import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"
import ReactApexChart from "react-apexcharts"
import { connect } from "react-redux"

import {GET_CAMPAIGNS} from "../../../helpers/url_helper"
import {get} from "../../../helpers/api_helper"

import campaign_icon from "../../../assets/images/madinad/campaign_red.svg"


const CampaignsChart = props => {
  
  const pieChartColors = ["#556ee6", "#34c38f", "#f46a6a", "#eb8f34", "#75502a", "#1ba6b5", "#0e269c", "#64687d", "#f2b305", "#f20538", "#113bc2", "#1e3d25", "#f07807"]

  const [availableCampaigns, setAvailableCampaigns] = useState([])
  const [websiteColors, setWebsiteColors] = useState({})
  const [pieChartData, setPieChartData] = useState({
    total_campaigns: 0, 
    data: {
      series: [],
      options: {
        labels: [],
        colors: ["#556ee6", "#34c38f", "#f46a6a"],
        legend: { show: !1 },
        dataLabels: {enabled: false},
        tooltip: {style: {fontSize: '11px',fontWeight: '400',fontFamily: undefined }},
        plotOptions: {pie: {donut: {size: "70%"}}}
      }
    }
  });
  const [loading, setLoading] = useState('loading');

  useEffect(() => {
      setLoading('loading'); 
      let website_id = ''
      // if (props.selectedWebsites.length === 1)
      //     website_id = props.selectedWebsites[0].id

      if (props.selectedDates.from || props.selectedDates.to) {
          get(GET_CAMPAIGNS, {
            run_form: props.selectedDates.from, 
            run_to: props.selectedDates.to,
            website_id
          })
          .then(response => {
              setAvailableCampaigns(response.results)
              // console.log("Pie chart get campaigns response = ", response.results)
          })
          .catch(error => {
              console.log("Campaign list error")
              console.log(error);
              setLoading('')
          })
      }
  }, [props.selectedDates])

  useEffect(() => {
    calculatePieData()
  }, [availableCampaigns, props.selectedWebsites])

  function calculatePieData() {
    var pie_colors = websiteColors
    let websites = {}
    let total_campaigns = 0
    props.selectedWebsites.map((x) => {websites[x.id] = {name: x.name, number: 0}})
    availableCampaigns.map((x) => {
      let website_has_this_campaign = 0;
      x.adplacements.map((y) => {
        if (websites[y.website]) {
          websites[y.website].number++
          website_has_this_campaign = 1
        }
      })
      total_campaigns += website_has_this_campaign
    })

    if (total_campaigns < 1 && props.selectedWebsites.length < 1) {
      setLoading('');
      return;
    }
  
    let series = []
    Object.entries(websites).map(([key, value]) => {
      series.push(value.number)
    })

    let labels = []
    labels = props.selectedWebsites.map((x) => x.name)
    if (JSON.stringify(pieChartData.data.series) == JSON.stringify(series) && JSON.stringify(pieChartData.data.options.labels) == JSON.stringify(labels)) {
      setLoading('');
      return;
    }

    // set colors the first time
    if ( Object.keys(websiteColors).length == 0 && labels.length > 0) {
      labels.map((x, index) => {
        pie_colors[x] = pieChartColors[index]
      })
      setWebsiteColors(pie_colors)
    }

    var temp = {...pieChartData}
    temp.total_campaigns = total_campaigns
    temp.data.series = series
    temp.data.options.labels = labels

    temp.data.options.colors = []
    temp.data.options.labels.map((x) => {
      temp.data.options.colors.push(pie_colors[x])
    })

    setPieChartData(temp)
    setLoading('');
  }

  return (
    <React.Fragment>
      <Col xl="4" className={loading}>
        <div className="cardwrapper">
          <Card className="canLoad">
          <div className="loader"></div>
            <CardBody>
              <div className="d-flex align-items-center justify-content-between mb-3">
                <h4 className="card-title icon-title mb-0">
                  <div><img src={campaign_icon}></img></div>Campaigns
                </h4>
                <Link
                      to="/campaigns"
                      className="btn btn-primary waves-effect waves-light btn-sm"
                    >
                      View Details{" "}
                      <i className="mdi mdi-chevron-right ml-1"></i>
                </Link>
              </div>
              <div>
                <div id="donut-chart" className="apex-charts">
                  <span>total <strong>{pieChartData.total_campaigns}</strong></span>
                  <ReactApexChart
                    key={pieChartData.data.options.labels + pieChartData.data.series}
                    options={pieChartData.data.options}
                    series={pieChartData.data.series}
                    type="donut"
                    height={245}
                  />
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
      </Col>
    </React.Fragment>
  )

}

const mapStateToProps = state => {
  return { 
      campaigns: state.campaigns.results,
  }
}

export default connect(mapStateToProps)(CampaignsChart)
// export default CampaignsChart
