import {
  GET_WEBSITES,
  GET_WEBSITES_SUCCESS,
  GET_WEBSITES_FAIL,
  GET_WEBSITE,
  GET_WEBSITE_SUCCESS,
  GET_WEBSITE_FAIL
} from "./actionTypes"

const initialState = {
    count: 0,
    next: null,
    previous: null,
    results: [],
    error: "",
    website: {},
    website_error: ""
}

const websites = (state = initialState, action) => {
    switch (action.type) {
      case GET_WEBSITES:
        state = { ...state}
        break
      case GET_WEBSITES_SUCCESS:
        state = { ...state, ...action.payload }
        break
      case GET_WEBSITES_FAIL:
        state = { ...state, error: action.payload }
        break
      case GET_WEBSITE:
        state = { ...state}
        break
      case GET_WEBSITE_SUCCESS:
        state = { ...state, website: action.payload }
        break
      case GET_WEBSITE_FAIL:
        state = { ...state, website_error: action.payload }
        break
      default:
        state = { ...state }
        break
    }
    return state
  }
  
  export default websites