import { call, put, takeEvery, takeLatest } from "redux-saga/effects"
import { GET_CAMPAIGNS, GET_CAMPAIGN, GET_CAMPAIGN_ANALYTICS } from "./actionTypes"
import { apiError, getCampaignsSuccess, getCampaignsFail, getCampaignSuccess, getCampaignFail, getCampaignAnalyticsSuccess, getCampaignAnalyticsFail } from "./actions"

import { getCampaigns, getCampaign, getCampaignAnalytics } from "../../../helpers/backend_helper"

function* getCampaignsInfo(params) {
    try {
        const response = yield call(getCampaigns, params.payload)
        if (response)
            yield put(getCampaignsSuccess(response))
        else
            yield put(getCampaignsFail('could not fetch campaigns'))
    } 
    catch (error) {
        yield put(getCampaignsFail("Bad response from server"))
    }
}

function* getCampaignInfo(id) {
    try {
        const response = yield call(getCampaign(id))
        if (response)
            yield put(getCampaignSuccess(response))
        else
            yield put(getCampaignFail('could not fetch campaign'))
    } 
    catch(error) {
        yield put(getCampaignFail("Bad response from server"))
    }
}

function* getCampaignAnalyticsInfo(id) {
    try {
        const response = yield call(getCampaignAnalytics(id))
        if (response)
            yield put(getCampaignAnalyticsSuccess(response))
        else
            yield put(getCampaignAnalyticsFail('could not fetch analytics'))
    } 
    catch(error) {
        yield put(getCampaignAnalyticsFail("Bad response from server"))
    }
}

function* campaignSaga() {
    yield takeEvery(GET_CAMPAIGNS, getCampaignsInfo)
    yield takeEvery(GET_CAMPAIGN, getCampaignInfo)
    yield takeEvery(GET_CAMPAIGN_ANALYTICS, getCampaignAnalyticsInfo)
}

export default campaignSaga