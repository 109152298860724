import { call, put, takeEvery, takeLatest } from "redux-saga/effects"
import { GET_ADTAGS } from "./actionTypes"
import { getAdtagsSuccess, getAdtagsFail } from "./actions"

import { getAdtags } from "../../../helpers/backend_helper"

function* getAdtagsInfo(params) {
    try {
        const response = yield call(getAdtags, params.payload)
        if (response)
            yield put(getAdtagsSuccess(response))
        else
            yield put(getAdtagsFail('could not fetch billing'))
    }
    catch (error) {
        yield put(getAdtagsFail("Bad response from server"))
    }
}

function* adtagsSagas() {
    yield takeEvery(GET_ADTAGS, getAdtagsInfo)
}

export default adtagsSagas